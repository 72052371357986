import { ExitToApp } from '@mui/icons-material';
import { Button } from 'antd';

import styles from './styles.module.css';

const AccountInfo = () => {
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className={styles['account-info']}>
      <div className={styles['info-content']}>
        <div className={styles['account-name']}>{localStorage.getItem('userEmail')}</div>
      </div>
      <Button type='link' size='small' className={styles['logout-btn']} onClick={handleLogout}>
        <ExitToApp />
      </Button>
    </div>
  );
};

export default AccountInfo;
