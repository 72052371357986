import { message } from 'antd';
import axios, { AxiosError } from 'axios';

import ApplicationApi from './application';
import AuthApi from './auth';
import CampaignApi from './campaign';
import RuleApi from './rule';
import ShopApi from './shop';
import UserApi from './user';

const BASE_PATH = process.env.REACT_APP_DROPFIT_API_URL || '';

const globalAxios = axios.create({
  baseURL: BASE_PATH,
});

const access_token = localStorage.getItem('access_token');
const token_type = localStorage.getItem('token_type');

globalAxios.interceptors.request.use(requestConfig => {
  const newConfig = requestConfig;
  newConfig.headers.Authorization = `${token_type} ${access_token}`;

  return newConfig;
});

globalAxios.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    console.log(error.response?.status);
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    if (error.response?.status === 400) {
      message.error(error.response?.data as string);
    } else {
      message.error('SERVER ERROR');
    }
    return Promise.reject(error);
  },
);

const api = {
  ApplicationApi: new ApplicationApi(globalAxios),
  AuthApi: new AuthApi(globalAxios),
  UserApi: new UserApi(globalAxios),
  CampaignApi: new CampaignApi(globalAxios),
  RuleApi: new RuleApi(globalAxios),
  ShopApi: new ShopApi(globalAxios),
  isAuthentication: !!access_token,
};

export default api;
