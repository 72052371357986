import LogoImage from 'images/logo.png';

import styles from './styles.module.css';

const Logo = () => {
  return (
    <>
      <div className={styles['logo-content']}>
        <img src={LogoImage} />
      </div>
      <div className={styles['border']} />
    </>
  );
};

export default Logo;
