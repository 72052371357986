import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Form, Image, Input, message, Modal } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import api from 'api';
import type { GuestApplicationData } from 'api/interface';
import { DetailsType } from 'global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { hasPermission } from 'utils';

interface ApplicationDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsType: DetailsType;
  data: GuestApplicationData;
}

const ApplicationDetailsModal: React.FC<ApplicationDetailsModalProps> = ({ open, setOpen, detailsType, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();

  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState<string>('');

  useEffect(() => {
    if (open) {
      if (detailsType === DetailsType.CREATE) {
        setImg('');
        form.resetFields();
      } else {
        form.setFieldsValue(data);
      }
    }
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values: GuestApplicationData = await form.validateFields();
      if (detailsType === DetailsType.CREATE) {
        await api.ApplicationApi.createApplication({ ...values, shopId: data.shopId });
      } else {
        await api.ApplicationApi.updateApplication({ ...values, shopId: data.shopId, applicationId: data.applicationId });
      }
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const props: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    headers: {
      Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
    },
    action: `${process.env.REACT_APP_DROPFIT_API_URL || ''}/childGuest/uploadImage`,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        setImg(info.file.response);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    console.log('Upload event:', e);
    // if (Array.isArray(e)) {
    //   console.log('here', e);
    //   return e;
    // }
    return e?.fileList[0].response;
  };

  return (
    <Modal
      title={detailsType === DetailsType.CREATE ? 'Create' : 'Edit'}
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText='Save'
      cancelText='Cancel'
      confirmLoading={loading}
    >
      <Form form={form} labelCol={{ span: 9 }}>
        <Form.Item label='Image' name='applicationImage' rules={[{ required: true }]} getValueFromEvent={normFile}>
          <Dragger {...props} maxCount={1} disabled={!hasPermission(['MEMBER'])}>
            {img || data.applicationImage ? (
              <>
                {img && <img src={img} style={{ width: '100%' }} />}
                {data.applicationImage && <Image src={data.applicationImage} style={{ width: '100%' }} />}
              </>
            ) : (
              <>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
              </>
            )}
          </Dragger>
        </Form.Item>
        <Form.Item label='Product URL' name='aliexpressUrl' rules={[{ required: true }]}>
          <Input placeholder='Please Input AliExpress Url Here' readOnly={!hasPermission(['MEMBER'])} />
        </Form.Item>
        {(hasPermission(['GUEST']) || hasPermission(['SUB-GUEST'])) && (
          <>
            <Form.Item label='Weight(Kg)' name='weight' rules={[{ required: true }]}>
              <Input placeholder='Please Input Weight Here' type='number' readOnly={data.updated != null} />
            </Form.Item>
            <Form.Item label='Price(¥)' name='rmbPrice' rules={[{ required: true }]}>
              <Input placeholder='Please Input Price Here' type='number' readOnly={data.updated != null} />
            </Form.Item>
            <Form.Item label='1688 Url' name='alibabaUrl' rules={[{ required: true }]}>
              <Input placeholder='Please Input AliExpress Url Here' readOnly={data.updated != null} />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ApplicationDetailsModal;
