import { Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { ShopData } from 'api/interface';
import { DynamicTable, PageHeader } from 'components';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch, RootState } from 'store';

import api from '../../../api';
import { DetailsType, ExtBtnType } from '../../../global';
import ShopDetailsModal from './ShopDetailsModal';

const Shops = () => {
  const { search } = useSelector((state: RootState) => state.dropFit);
  const { loading } = useSelector((state: RootState) => state.loading.effects.dropFit.searchShops);
  const dispatch = useDispatch<Dispatch>();
  const [openModal, setOpenModal] = useState(false);
  const [detailsType, setDetailsType] = useState<DetailsType>(DetailsType.CREATE);
  const [formData, setFormData] = useState<ShopData>({});
  const navigate = useNavigate();

  const handleCreate = () => {
    setDetailsType(DetailsType.CREATE);
    setOpenModal(true);
  };

  const handleEdit = (shopId?: string) => {
    if (shopId == undefined || shopId === '') {
      return;
    }
    api.ShopApi.getShopById(shopId).then(res => {
      setFormData(res.data);
      setDetailsType(DetailsType.EDIT);
      setOpenModal(true);
    });
  };

  const handleEditCampaigns = (shopId?: string) => {
    navigate(`/main/shops/${shopId}/campaigns`);
  };

  const handleDelete = (shopId?: string) => {
    if (shopId == undefined || shopId === '') {
      return;
    }
    Modal.confirm({
      title: 'Delete Shop',
      content: 'Are you confirm to delete? All campaigns under this shop will be deleted and this can not be undone!',
      icon: null,
      okText: 'OK',
      cancelText: 'Cancel',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        await api.ShopApi.deleteShop(shopId);
        dispatch.dropFit.reloadTable(true);
      },
    });
  };

  const columns: ColumnsType<ShopData> = [
    { title: 'Brand Name', align: 'center', dataIndex: 'brandName' },
    { title: 'Shopify Store ID', align: 'center', dataIndex: 'shopName' },
    { title: 'VAT Rate', align: 'center', dataIndex: 'vatRate' },
    {
      title: 'Operations',
      align: 'center',
      render: (data: ShopData) => (
        <>
          <Button type='link' onClick={() => handleEdit(data.shopId)}>
            Edit Shop
          </Button>
          <Button type='link' onClick={() => handleEditCampaigns(data.shopId)}>
            Go to Campaigns
          </Button>
          <Button type='link' onClick={() => handleDelete(data.shopId)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Shop Management' extBtnType={ExtBtnType.CREATE} extBtnAction={handleCreate} />

      <DynamicTable
        columns={columns}
        fetch={dispatch.dropFit.searchShops}
        dataSource={search?.searchContext?.rows}
        totalCount={search?.searchContext?.totalCount}
        pagination={false}
        rowKey='shopId'
        loading={loading || false}
      />

      <ShopDetailsModal open={openModal} setOpen={setOpenModal} detailsType={detailsType} data={formData} />
    </>
  );
};

export default Shops;
