import { DatePicker, Modal, Spin, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import { ColumnsType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import React from 'react';

import api from '../../../api';
import { GuestShopStatisticsDisplayData } from '../../../api/interface';
import styles from './styles.module.css';

interface ShopStatisticsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shopId: string;
  shopName: string;
}

const ShopStatisticsModal: React.FC<ShopStatisticsModalProps> = ({ open, setOpen, shopId, shopName }) => {
  const [data, setData] = useState<GuestShopStatisticsDisplayData[]>([]);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();

  useEffect(() => {
    if (open && shopId !== '') {
      setData([]);
      setLoading(true);
      api.CampaignApi.getGuestShopStatistics(shopId)
        .then(res => {
          const data = [];
          for (let i = 0; i < res.data.length; i++) {
            data.push({
              orderName: res.data[i].orderName,
              orderCogs: res.data[i].orderCogs,
              totalCogs: res.data[i].totalCogs,
            });
            for (let j = 0; j < res.data[i].productList.length; j++) {
              data.push({
                // orderName: res.data[i].orderName,
                // orderCogs: res.data[i].orderCogs,
                // totalCogs: res.data[i].totalCogs,
                productId: res.data[i].productList[j].productId,
                cog: res.data[i].productList[j].cog,
                count: res.data[i].productList[j].count,
                msg: res.data[i].productList[j].msg,
              });
            }
          }
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open]);

  const handleChangeDateRange = (value: [Dayjs | null, Dayjs | null] | null) => {
    if (value != undefined && value[0] != undefined && value[1] != undefined) {
      const from = dayjs(value[0]).format('YYYY-MM-DD');
      const to = dayjs(value[1]).format('YYYY-MM-DD');
      setFrom(from);
      setTo(to);
    } else {
      setFrom(undefined);
      setTo(undefined);
    }
  };

  const handleFilter = () => {
    setLoading(true);
    api.CampaignApi.getGuestShopStatistics(shopId, from, to)
      .then(res => {
        const data = [];
        for (let i = 0; i < res.data.length; i++) {
          data.push({
            orderName: res.data[i].orderName,
            orderCogs: res.data[i].orderCogs,
            totalCogs: res.data[i].totalCogs,
          });
          for (let j = 0; j < res.data[i].productList.length; j++) {
            data.push({
              // orderName: res.data[i].orderName,
              // orderCogs: res.data[i].orderCogs,
              // totalCogs: res.data[i].totalCogs,
              productId: res.data[i].productList[j].productId,
              cog: res.data[i].productList[j].cog,
              count: res.data[i].productList[j].count,
              msg: res.data[i].productList[j].msg,
            });
          }
        }
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFrom(undefined);
    setTo(undefined);
    setOpen(false);
  };

  const columns: ColumnsType<GuestShopStatisticsDisplayData> = [
    {
      title: 'Shopify Order',
      align: 'center',
      dataIndex: 'orderName',
      render: orderName => (
        <>
          <p className={styles['orderName']}>{orderName}</p>
        </>
      ),
    },
    {
      title: 'COGs',
      align: 'center',
      dataIndex: 'orderCogs',
      render: cog => (
        <>
          <p className={styles['orderName']}>{cog}</p>
        </>
      ),
    },
    { title: 'ProductId', align: 'center', dataIndex: 'productId' },
    { title: 'Product Cog', align: 'center', dataIndex: 'cog' },
    { title: 'Count', align: 'center', dataIndex: 'count' },
    { title: 'Error', align: 'center', dataIndex: 'msg' },

    // {
    //   title: 'Error Msg',
    //   align: 'center',
    //   render: (data: GuestShopStatisticsData) => (
    //     <>
    //       <div>{data.msg}</div>
    //     </>
    //   ),
    // },
  ];

  return (
    <Modal
      width={1200}
      title={`Shop Statistics : ${shopName}, Total Cogs: ${data.length > 0 ? data[0].totalCogs : 0}`}
      open={open}
      onCancel={handleClose}
      cancelText='Close'
      okText='Filter'
      onOk={handleFilter}
    >
      <Spin size='large' spinning={loading}>
        <DatePicker.RangePicker
          locale={locale}
          value={from && to ? [dayjs(`${from}`), dayjs(`${to}`)] : null}
          onChange={value => handleChangeDateRange(value)}
        />
        <Table className='mt-10' columns={columns} dataSource={data} pagination={false} />
      </Spin>
    </Modal>
  );
};

export default ShopStatisticsModal;
