import { Button, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { GuestCampaignData } from 'api/interface';
import { DynamicTable, PageHeader } from 'components';
import { DetailsType, ExtBtnType } from 'global';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, RootState } from 'store';
import { initialFetch } from 'store/models/dropFit';

import api from '../../../api';
import { SearchItemType } from '../../../components/FilterBar';
import CampaignDetailsModal from './CampaignDetailsModal';

const GuestCampaigns = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useSelector((state: RootState) => state.dropFit);
  const { loading } = useSelector((state: RootState) => state.loading.effects.dropFit.searchGuestCampaigns);
  const dispatch = useDispatch<Dispatch>();
  const [openModal, setOpenModal] = useState(false);
  const [detailsType, setDetailsType] = useState<DetailsType>(DetailsType.CREATE);
  const [formData, setFormData] = useState<GuestCampaignData>({ shopId: id });

  const handleCreate = () => {
    setDetailsType(DetailsType.CREATE);
    setOpenModal(true);
  };

  const handleEdit = (data: GuestCampaignData) => {
    setFormData({ ...data, shopId: id, campaignId: data.campaignId });
    setDetailsType(DetailsType.EDIT);
    setOpenModal(true);
  };

  const handleDelete = (campaignId?: string) => {
    if (campaignId == undefined || campaignId === '') {
      return;
    }
    Modal.confirm({
      title: 'Delete Campaign',
      content: 'Are you confirm to delete? This can not be undone!',
      icon: null,
      okText: 'OK',
      cancelText: 'Cancel',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        await api.CampaignApi.deleteGuestCampaign(campaignId);
        dispatch.dropFit.reloadTable(true);
      },
    });
  };

  const columns: ColumnsType<GuestCampaignData> = [
    { title: 'Shopify Product Id', align: 'center', dataIndex: 'shopifyProductId' },
    { title: 'cog1', align: 'center', dataIndex: 'cog1' },
    { title: 'cog2', align: 'center', dataIndex: 'cog2' },
    { title: 'cog3', align: 'center', dataIndex: 'cog3' },
    { title: 'cog4', align: 'center', dataIndex: 'cog4' },
    { title: 'cog5', align: 'center', dataIndex: 'cog5' },
    {
      title: 'Operations',
      align: 'center',
      render: (data: GuestCampaignData) => (
        <>
          <Button type='link' onClick={() => handleEdit(data)}>
            Edit Product
          </Button>
          <Button type='link' onClick={() => handleDelete(data.campaignId)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Product List' extBtnType={ExtBtnType.CREATE} extBtnAction={handleCreate} />

      <DynamicTable
        columns={columns}
        initialContext={
          id === search?.campaignsFetchParams?.fixed?.shopId
            ? search?.campaignsFetchParams
            : {
                ...initialFetch,
                fixed: { shopId: id },
              }
        }
        fetch={dispatch.dropFit.searchGuestCampaigns}
        dataSource={search?.searchContext?.rows}
        searchItems={[
          {
            label: 'Shopify Product Id',
            type: SearchItemType.STRING,
            key1: 'shopifyProductId',
          },
        ]}
        totalCount={search?.searchContext?.totalCount}
        rowKey='campaignId'
        loading={loading || false}
      />
      <CampaignDetailsModal open={openModal} setOpen={setOpenModal} detailsType={detailsType} data={formData} />
    </>
  );
};

export default GuestCampaigns;
