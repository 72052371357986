import { AxiosPromise } from 'axios';

import { BaseApi, RequestArgs } from './base';
import { createRequestFunction } from './common';
import type { UserData, UsersFetchParams } from './interface';

export default class UserApi extends BaseApi {
  changeConnectedStatus(userId: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/admin/changeConnectedStatus`,
      options: {
        method: 'POST',
        params: {
          userId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getUserList(params: UsersFetchParams): AxiosPromise<UserData[]> {
    const { pageSize, currentPage, search } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/admin/getUserList`,
      options: {
        method: 'GET',
        params: {
          pageSize,
          currentPage,
          ...search,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getChildGuestList(params: UsersFetchParams): AxiosPromise<UserData[]> {
    const { pageSize, currentPage, search } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/childGuest/getList`,
      options: {
        method: 'GET',
        params: {
          pageSize,
          currentPage,
          ...search,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getSubUserList(params: UsersFetchParams): AxiosPromise<UserData[]> {
    const { pageSize, currentPage, search } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/user/getSubUserList`,
      options: {
        method: 'GET',
        params: {
          pageSize,
          currentPage,
          ...search,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createUser(params: UserData) {
    const { ...props } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/admin/createUser`,
      options: {
        method: 'POST',
        params: props,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createGuestChild(params: UserData) {
    const { ...props } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/childGuest/create`,
      options: {
        method: 'POST',
        params: props,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createSubUser(params: UserData) {
    const { ...props } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/user/createSubUser`,
      options: {
        method: 'POST',
        params: props,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateUser(params: UserData) {
    const { ...props } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/admin/resetPassword`,
      options: {
        method: 'POST',
        params: props,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateGuestChild(params: UserData) {
    const { ...props } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/childGuest/update`,
      options: {
        method: 'POST',
        params: props,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateSubUser(params: UserData) {
    const { ...props } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/user/resetPassword`,
      options: {
        method: 'POST',
        params: props,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  changePassword(oldPassword: string, newPassword: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/auth/changePassword`,
      options: {
        method: 'POST',
        params: {
          oldPassword,
          newPassword,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateLineToken(lineToken?: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/auth/updateLineToken`,
      options: {
        method: 'POST',
        params: {
          lineToken,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getUserInfo(): AxiosPromise<UserData> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/auth/getUserInfo`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
