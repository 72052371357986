import { Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { GuestShopData } from 'api/interface';
import { DynamicTable, PageHeader } from 'components';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch, RootState } from 'store';
import { hasPermission } from 'utils';

import api from '../../../api';
import { DetailsType, ExtBtnType } from '../../../global';
import ShopDetailsModal from './ShopDetailsModal';
import ShopStatisticsModal from './ShopStatisticsModal';

const GuestShops = () => {
  const { search } = useSelector((state: RootState) => state.dropFit);
  const { loading } = useSelector((state: RootState) => state.loading.effects.dropFit.searchGuestShops);
  const dispatch = useDispatch<Dispatch>();
  const [shopId, setShopId] = useState<string>('');
  const [shopName, setShopName] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const [openStatisticsModal, setOpenStatisticsModal] = useState(false);
  const [detailsType, setDetailsType] = useState<DetailsType>(DetailsType.CREATE);
  const [formData, setFormData] = useState<GuestShopData>({});
  const navigate = useNavigate();

  const handleCreate = () => {
    setDetailsType(DetailsType.CREATE);
    setOpenModal(true);
  };

  const handleEdit = (shop?: GuestShopData) => {
    if (!shop) {
      return;
    }
    setFormData(shop);
    setDetailsType(DetailsType.EDIT);
    setOpenModal(true);
  };

  const handleCheckStatistics = (shop?: GuestShopData) => {
    if (!shop) {
      return;
    }
    setShopId(shop.shopId || '');
    setShopName(shop.brandName || '');
    setOpenStatisticsModal(true);
  };

  const handleEditCampaigns = (shopId?: string) => {
    navigate(`/main/guestShops/${shopId}/campaigns`);
  };

  const handleEditApplications = (shopId?: string) => {
    navigate(`/main/guestShops/${shopId}/applications`);
  };

  const handleDelete = (shopId?: string) => {
    if (shopId == undefined || shopId === '') {
      return;
    }
    Modal.confirm({
      title: 'Delete Shop',
      content: 'Are you confirm to delete? All campaigns under this shop will be deleted and this can not be undone!',
      icon: null,
      okText: 'OK',
      cancelText: 'Cancel',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        await api.ShopApi.deleteGuestShop(shopId);
        dispatch.dropFit.reloadTable(true);
      },
    });
  };

  const columns: ColumnsType<GuestShopData> = [
    { title: 'Brand Name', align: 'center', dataIndex: 'brandName' },
    { title: 'Shopify Store ID', align: 'center', dataIndex: 'shopName' },
    {
      title: 'Operations',
      align: 'center',
      render: (data: GuestShopData) => (
        <>
          {hasPermission(['GUEST']) && (
            <Button type='link' onClick={() => handleEdit(data)}>
              Edit Shop
            </Button>
          )}
          {hasPermission(['MEMBER']) && (
            <Button type='link' onClick={() => handleEditApplications(data.shopId)}>
              Go to Applications
            </Button>
          )}
          {(hasPermission(['GUEST']) || hasPermission(['MEMBER'])) && (
            <Button type='link' onClick={() => handleCheckStatistics(data)}>
              Shop Statistics
            </Button>
          )}
          {hasPermission(['GUEST']) && (
            <Button type='link' onClick={() => handleEditCampaigns(data.shopId)}>
              Go to COGs
            </Button>
          )}
          {hasPermission(['GUEST']) && (
            <Button type='link' onClick={() => handleDelete(data.shopId)}>
              Delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Shop Management' extBtnType={ExtBtnType.CREATE} extBtnAction={handleCreate} createPermission={['GUEST']} />

      <DynamicTable
        columns={columns}
        fetch={dispatch.dropFit.searchGuestShops}
        dataSource={search?.searchContext?.rows}
        totalCount={search?.searchContext?.totalCount}
        pagination={false}
        rowKey='shopId'
        loading={loading || false}
      />

      <ShopDetailsModal open={openModal} setOpen={setOpenModal} detailsType={detailsType} data={formData} />
      <ShopStatisticsModal open={openStatisticsModal} setOpen={setOpenStatisticsModal} shopId={shopId} shopName={shopName} />
    </>
  );
};

export default GuestShops;
