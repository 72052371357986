import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import Settings from './settings';
import Shop from './shop';
import Statistics from './statistics';

const DropFitPage = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

const DropFitIndex = () => (
  <Routes>
    <Route path='/*' element={<DropFitPage />}>
      <Route path='main/*' element={<Shop />} />
      <Route path='settings/*' element={<Settings />} />
      <Route path='statistics/*' element={<Statistics />} />
      <Route path='*' element={<Navigate to='/main' replace />} />
    </Route>
  </Routes>
);

export default DropFitIndex;
