import './index.css';
import 'dayjs/locale/en';

import { ConfigProvider } from 'antd';
import locale from 'antd/locale/en_US';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConfigProvider
    locale={locale}
    theme={{ token: { colorPrimary: '#2a82e4', colorError: '#d43030', colorTextPlaceholder: '#333333', borderRadius: 5 } }}
  >
    <Suspense fallback={<div>123</div>}>
      <Provider store={store}>
        <PersistGate loading={<div>123</div>} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Suspense>
  </ConfigProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
