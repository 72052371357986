import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, InputRef, Modal, Select, Space } from 'antd';
import api from 'api';
import type { ConnectedShopData } from 'api/interface';
import { DetailsType } from 'global';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

interface ShopDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsType: DetailsType;
  data: ConnectedShopData;
}

const ShopDetailsModal: React.FC<ShopDetailsModalProps> = ({ open, setOpen, detailsType, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState<string[]>([]);
  const [newUser, setNewUser] = useState('');
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (open) {
      setLoading(true);
      api.ShopApi.getUserList()
        .then(res => {
          setUserList(res.data);
        })
        .finally(() => {
          setLoading(false);
          if (detailsType === DetailsType.CREATE) {
            form.resetFields();
          } else {
            form.setFieldsValue(data);
          }
        });
    }
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values: ConnectedShopData = await form.validateFields();
      if (detailsType === DetailsType.CREATE) {
        await api.ShopApi.createConnectedShop(values);
      } else {
        await api.ShopApi.updateConnectedShop({ ...values, shopId: data.shopId });
      }
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNewUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser(event.target.value);
  };

  const handleNewUser = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setUserList([...userList, newUser]);
    setNewUser('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Modal
      title={detailsType === DetailsType.CREATE ? 'Create' : 'Edit'}
      width={800}
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText='Save'
      cancelText='Cancel'
      confirmLoading={loading}
    >
      <Form form={form} labelCol={{ span: 8 }}>
        <Form.Item label='Shopify Store ID' name='shopName' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Store ID Here...' />
        </Form.Item>
        <Form.Item label='Brand Name' name='brandName' rules={[{ required: true }]}>
          <Input placeholder='Please Input Brand Name Here...' />
        </Form.Item>
        <Form.Item label='Shopify Admin API Access Token' name='shopifyAccessToken' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Admin API Access Token Here...' />
        </Form.Item>
        <Form.Item label='Shopify API Key' name='shopifyAPIKey' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify API Key Here...' />
        </Form.Item>
        <Form.Item label='Country' name='country' rules={[{ required: true }]}>
          <Input placeholder='Please Input Country Here...' />
        </Form.Item>
        <Form.Item label='User' name='user' rules={[{ required: true }]}>
          <Select
            style={{ width: 300 }}
            placeholder='Please Input User Here...'
            dropdownRender={menu => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Input
                    placeholder='Please enter item'
                    ref={inputRef}
                    value={newUser}
                    onChange={handleNewUserChange}
                    onKeyDown={e => e.stopPropagation()}
                  />
                  <Button type='text' icon={<PlusOutlined />} onClick={handleNewUser}>
                    Add item
                  </Button>
                </Space>
              </>
            )}
            options={userList.map(item => ({ label: item, value: item }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShopDetailsModal;
