import { Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { ConnectedShopData } from 'api/interface';
import { DynamicTable, PageHeader } from 'components';
import { SearchItemType } from 'components/FilterBar';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import api from '../../../api';
import { DetailsType, ExtBtnType } from '../../../global';
import ShopDetailsModal from './ShopDetailsModal';

const ConnectedShops = () => {
  const { search } = useSelector((state: RootState) => state.dropFit);
  const { loading } = useSelector((state: RootState) => state.loading.effects.dropFit.searchConnectedShops);
  const dispatch = useDispatch<Dispatch>();
  const [openModal, setOpenModal] = useState(false);
  const [detailsType, setDetailsType] = useState<DetailsType>(DetailsType.CREATE);
  const [formData, setFormData] = useState<ConnectedShopData>({});

  const handleCreate = () => {
    setDetailsType(DetailsType.CREATE);
    setOpenModal(true);
  };

  const handleEdit = (shop?: ConnectedShopData) => {
    if (!shop) {
      return;
    }
    setFormData(shop);
    setDetailsType(DetailsType.EDIT);
    setOpenModal(true);
  };

  const handleDelete = (shopId?: string) => {
    if (shopId == undefined || shopId === '') {
      return;
    }
    Modal.confirm({
      title: 'Delete Shop',
      content: 'Are you confirm to delete?',
      icon: null,
      okText: 'OK',
      cancelText: 'Cancel',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        await api.ShopApi.deleteConnectedShop(shopId);
        dispatch.dropFit.reloadTable(true);
      },
    });
  };

  const columns: ColumnsType<ConnectedShopData> = [
    { title: 'Brand Name', align: 'center', dataIndex: 'brandName' },
    { title: 'Shopify Store ID', align: 'center', dataIndex: 'shopName' },
    { title: 'Country', align: 'center', dataIndex: 'country' },
    { title: 'User', align: 'center', dataIndex: 'user' },
    {
      title: 'Order Count',
      align: 'center',
      render: (data: ConnectedShopData) => (
        <>
          <div>{data.orderCount != null && data.orderCount >= 0 ? data.orderCount : 'Invalid Shopify Info'}</div>
        </>
      ),
    },
    {
      title: 'Total Order Count',
      align: 'center',
      dataIndex: 'totalOrderCount',
    },
    {
      title: 'Operations',
      align: 'center',
      render: (data: ConnectedShopData) => (
        <>
          <Button type='link' onClick={() => handleEdit(data)}>
            Edit Shop
          </Button>
          <Button type='link' onClick={() => handleDelete(data.shopId)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Shop Statistics' extBtnType={ExtBtnType.CREATE} extBtnAction={handleCreate} />

      <DynamicTable
        columns={columns}
        fetch={dispatch.dropFit.searchConnectedShops}
        initialContext={search?.connectedShopsFetchParams}
        dataSource={search?.searchContext?.rows}
        searchItems={[
          {
            label: 'Date',
            type: SearchItemType.DATE_RANGE,
            key1: 'from',
            key2: 'to',
          },
          {
            label: 'Country',
            type: SearchItemType.STRING,
            key1: 'country',
          },
          {
            label: 'User',
            type: SearchItemType.STRING,
            key1: 'user',
          },
        ]}
        totalCount={search?.searchContext?.totalCount}
        pagination={false}
        rowKey='shopId'
        loading={loading || false}
      />

      <ShopDetailsModal open={openModal} setOpen={setOpenModal} detailsType={detailsType} data={formData} />
    </>
  );
};

export default ConnectedShops;
