import { Campaigns, GuestApplications, GuestCampaigns, GuestShops, Rules, Shops } from 'pages';
import { Route, Routes } from 'react-router-dom';

const Shop = () => (
  <Routes>
    <Route path='/guestShops' element={<GuestShops />} />
    <Route path='/guestShops/:id/campaigns' element={<GuestCampaigns />} />
    <Route path='/guestShops/:id/applications' element={<GuestApplications />} />
    <Route path='/guestApplications' element={<GuestApplications />} />
    <Route path='/shops' element={<Shops />} />
    <Route path='/shops/:id/campaigns' element={<Campaigns />} />
    <Route path='/rules' element={<Rules />} />
  </Routes>
);

export default Shop;
