/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, ConfigProvider, DatePicker, Input, InputNumber, Select } from 'antd';
import type { BaseOptionType } from 'antd/es/select';
import { Map } from 'api/interface';
import { RangeValue } from 'global';
import { useState } from 'react';
import { convertDayjsToString, convertStringToDayjs } from 'utils';

import styles from './styles.module.css';

export enum SearchItemType {
  NUMBER,
  STRING,
  DATE,
  DATE_RANGE,
  SELECT,
  ORDER_SERVICE_DATE_TIME,
  ADDRESS,
}

export interface SearchItem {
  label: string;
  type: SearchItemType;
  placeholder?: string;
  key1: string;
  key2?: string;
  key3?: string;
  options?: BaseOptionType[];
}

interface FilterBarProps {
  searchItems: SearchItem[];
  initialFilters: Map;
  setNewFilters: React.Dispatch<React.SetStateAction<Map>>;
}

const FilterBar = ({ searchItems, initialFilters, setNewFilters }: FilterBarProps) => {
  const [filters, setFilters] = useState(initialFilters);

  const handleChangeFilters = (key: string, value: any) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
  };

  const handleChangeDateRangeFilter = (key1: string, key2: string, value: RangeValue) => {
    const newFilters = { ...filters };
    newFilters[key1] = Array.isArray(value) ? convertDayjsToString(value[0]) : null;
    newFilters[key2] = Array.isArray(value) ? convertDayjsToString(value[1]) : null;
    setFilters(newFilters);
  };

  const handleSearch = () => {
    setNewFilters(filters);
  };

  const handleSearchClear = () => {
    setFilters({});
    setNewFilters({});
  };

  const renderSearchItem = (searchItem: SearchItem) => {
    switch (searchItem.type) {
      case SearchItemType.NUMBER:
        return (
          <InputNumber
            className={styles['input']}
            placeholder={searchItem.placeholder}
            value={filters[searchItem.key1]}
            onChange={value => handleChangeFilters(searchItem.key1, value)}
          />
        );
      case SearchItemType.STRING:
        return (
          <Input
            className={styles['input']}
            placeholder={searchItem.placeholder}
            value={filters[searchItem.key1]}
            onChange={e => handleChangeFilters(searchItem.key1, e.currentTarget.value)}
          />
        );
      case SearchItemType.SELECT:
        return (
          <Select
            className={styles['input']}
            value={filters[searchItem.key1]}
            placeholder={searchItem.placeholder}
            options={searchItem.options}
            onChange={value => handleChangeFilters(searchItem.key1, value)}
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        );
      case SearchItemType.DATE_RANGE:
        return (
          <DatePicker.RangePicker
            className={styles['date-range']}
            value={[convertStringToDayjs(filters[searchItem.key1]), convertStringToDayjs(filters[searchItem.key2 || ''])]}
            onChange={value => handleChangeDateRangeFilter(searchItem.key1, searchItem.key2 || '', value)}
          />
        );
      case SearchItemType.ORDER_SERVICE_DATE_TIME:
        return (
          <div>
            <DatePicker
              value={convertStringToDayjs(filters[searchItem.key1])}
              onChange={value => handleChangeFilters(searchItem.key1, convertDayjsToString(value))}
            />
            <Select
              className={styles['order-service-time']}
              placeholder='请选择'
              value={filters[searchItem.key2 || '']}
              options={searchItem.options}
              onChange={value => handleChangeFilters(searchItem.key2 || '', value)}
            />
          </div>
        );
      case SearchItemType.ADDRESS:
        return (
          <>
            <Input
              style={{ width: 120 }}
              value={filters[searchItem.key1]}
              onChange={event => handleChangeFilters(searchItem.key1, event.currentTarget.value)}
              placeholder='姓名'
            />
            <Input
              style={{ width: 120, marginLeft: 15 }}
              value={filters[searchItem.key2 || '']}
              onChange={event => handleChangeFilters(searchItem.key2 || '', event.currentTarget.value)}
              placeholder='手机号'
            />
          </>
        );
    }
  };

  return (
    <div className={styles['filter-bar']}>
      <ConfigProvider theme={{ token: { controlHeight: 30, fontSize: 12 } }}>
        <div className={styles['search-items-wrap']}>
          {searchItems.map((searchItem, index) => (
            <div className={styles['search-item']} key={index}>
              <label className={styles['label']}>{searchItem.label}：</label>
              {renderSearchItem(searchItem)}
            </div>
          ))}
        </div>
      </ConfigProvider>
      <div className={styles['search-btns']}>
        <ConfigProvider theme={{ token: { colorPrimary: '#ff5733', fontSizeLG: 14 } }}>
          <Button type='primary' size='large' className={styles['search-btn']} onClick={handleSearch}>
            Search
          </Button>
        </ConfigProvider>
        <ConfigProvider theme={{ token: { colorPrimary: '#a6a6a6', fontSizeLG: 14 } }}>
          <Button type='primary' size='large' className={styles['search-btn']} onClick={handleSearchClear}>
            Reset
          </Button>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default FilterBar;
