import { Models } from '@rematch/core';

import { dropFit } from './dropFit';

export interface RootModel extends Models<RootModel> {
  dropFit: typeof dropFit;
}

export const models: RootModel = {
  dropFit: dropFit,
};
