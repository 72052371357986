import { Form, Input, Modal } from 'antd';
import api from 'api';
import type { GuestCampaignData } from 'api/interface';
import { DetailsType } from 'global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

interface CampaignDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsType: DetailsType;
  data: GuestCampaignData;
}

const CampaignDetailsModal: React.FC<CampaignDetailsModalProps> = ({ open, setOpen, detailsType, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (detailsType === DetailsType.CREATE) {
        form.resetFields();
      } else {
        form.setFieldsValue(data);
      }
    }
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values: GuestCampaignData = await form.validateFields();
      if (detailsType === DetailsType.CREATE) {
        await api.CampaignApi.createGuestCampaign({ ...values, shopId: data.shopId });
      } else {
        await api.CampaignApi.updateGuestCampaign({ ...values, shopId: data.shopId, campaignId: data.campaignId });
      }
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={detailsType === DetailsType.CREATE ? 'Create' : 'Edit'}
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText='Save'
      cancelText='Cancel'
      confirmLoading={loading}
    >
      <Form form={form} labelCol={{ span: 9 }}>
        <Form.Item label='Shopify Product Id' name='shopifyProductId' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Product Id Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog1' name='cog1' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog1 Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog2' name='cog2' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog2 Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog3' name='cog3' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog3 Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog4' name='cog4' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog4 Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog5' name='cog5' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog5 Here...' type='number' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CampaignDetailsModal;
