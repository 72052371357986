import { Button, ConfigProvider, Input } from 'antd';
import api from 'api';
import { useState } from 'react';

import styles from './styles.module.css';

const Login = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const handleSubmit = async () => {
    try {
      const res = await api.AuthApi.login({ userEmail, userPassword });

      localStorage.setItem('access_token', res.data.access_token);
      localStorage.setItem('token_type', res.data.token_type);
      localStorage.setItem('authorities', JSON.stringify(res.data.authorities));
      localStorage.setItem('userEmail', res.data.userEmail);

      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles['main-content']}>
      <div className={styles['left-content']}>
        {/* <div className={styles['header-content']}>
          <img src={Logo} />
        </div> */}
        <div className={styles['img-content']} />
      </div>
      <div className={styles['login-content']}>
        <div className={styles['title']}>
          <span>Sign in to your account</span>
        </div>

        <div className={styles['border']} />

        <ConfigProvider theme={{ token: { colorTextPlaceholder: '#a6a6a6', controlHeightLG: 48, fontSizeLG: 18 } }}>
          <Input
            size='large'
            placeholder='Please Input Email Here'
            prefix={<div className={styles['input-prefix']}>Email</div>}
            value={userEmail}
            onChange={e => setUserEmail(e.currentTarget.value)}
            onPressEnter={handleSubmit}
            style={{ marginBottom: 20 }}
          />
          <Input.Password
            size='large'
            placeholder='Please Input Password Here'
            prefix={<div className={styles['input-prefix']}>Password</div>}
            value={userPassword}
            onChange={e => setUserPassword(e.currentTarget.value)}
            onPressEnter={handleSubmit}
            style={{ marginBottom: 20 }}
          />
          <ConfigProvider theme={{ token: { colorPrimary: '#e27c1f', controlHeightLG: 52, fontSizeLG: 18 } }}>
            <Button size='large' type='primary' onClick={handleSubmit}>
              Login
            </Button>
          </ConfigProvider>
        </ConfigProvider>
        <span className={styles['note']}>Only invited members are allowed access!</span>
      </div>
    </div>
  );
};

export default Login;
