import { AxiosPromise } from 'axios';

import { BaseApi, RequestArgs } from './base';
import { createRequestFunction } from './common';
import { CampaignData, CampaignFetchParams, GuestCampaignData, GuestShopStatisticsData } from './interface';

export default class CampaignApi extends BaseApi {
  getGuestCampaignList(shopId: string, params: CampaignFetchParams): AxiosPromise<CampaignData[]> {
    const { pageSize, currentPage, search } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestCampaign/getList`,
      options: {
        method: 'GET',
        params: {
          shopId,
          pageSize,
          currentPage,
          ...search,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
  getCampaignList(shopId: string, params: CampaignFetchParams): AxiosPromise<CampaignData[]> {
    const { pageSize, currentPage, search } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/campaign/getList`,
      options: {
        method: 'GET',
        params: {
          shopId,
          pageSize,
          currentPage,
          ...search,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getAdAccountList(shopId: string): AxiosPromise<string[]> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/campaign/getAdAccountList`,
      options: {
        method: 'GET',
        params: {
          shopId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getGuestShopStatistics(shopId: string, from?: string, to?: string): AxiosPromise<GuestShopStatisticsData[]> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestShop/getStatistics`,
      options: {
        method: 'GET',
        params: {
          shopId,
          from,
          to,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getCampaignById(campaignId: string, from?: string, to?: string): AxiosPromise<CampaignData> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/campaign/getById`,
      options: {
        method: 'GET',
        params: {
          campaignId,
          from,
          to,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createGuestCampaign(data: GuestCampaignData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestCampaign/create`,
      options: {
        method: 'POST',
        params: data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createCampaign(data: CampaignData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/campaign/create`,
      options: {
        method: 'POST',
        params: data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateGuestCampaign(data: GuestCampaignData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestCampaign/update`,
      options: {
        method: 'POST',
        params: data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateCampaign(data: CampaignData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/campaign/update`,
      options: {
        method: 'POST',
        params: data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteGuestCampaign(campaignId: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestCampaign/delete`,
      options: {
        method: 'POST',
        params: {
          campaignId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteCampaign(campaignId: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/campaign/delete`,
      options: {
        method: 'POST',
        params: {
          campaignId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
