import { Form, Input, Modal, Select } from 'antd';
import api from 'api';
import type { UserData } from 'api/interface';
import { DetailsType } from 'global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

interface UserDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsType: DetailsType;
  data: UserData;
}

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({ open, setOpen, detailsType, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (detailsType === DetailsType.CREATE) {
        form.resetFields();
      } else {
        form.setFieldsValue(data);
      }
    }
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values: UserData = await form.validateFields();
      if (detailsType === DetailsType.CREATE) {
        await api.UserApi.createGuestChild(values);
      } else {
        await api.UserApi.updateGuestChild({ ...values, userId: data.userId });
      }
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={detailsType === DetailsType.CREATE ? 'Create' : 'Edit'}
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText='确认'
      confirmLoading={loading}
    >
      <Form form={form} labelCol={{ span: 4 }}>
        <Form.Item label='Email' name='userEmail' rules={[{ required: true }]}>
          <Input placeholder='Please Input Email Here...' readOnly={detailsType === DetailsType.EDIT} />
        </Form.Item>
        <Form.Item label='Role' name='userRole' rules={[{ required: true }]}>
          <Select
            placeholder='Please Select User Role'
            options={[
              { label: 'SUB-GUEST', value: 'SUB-GUEST' },
              { label: 'MEMBER', value: 'MEMBER' },
            ]}
            disabled={detailsType === DetailsType.EDIT}
          />
        </Form.Item>
        <Form.Item label='Password' name='userPassword' rules={[{ required: true }]}>
          <Input.Password placeholder='Please Input Password Here...' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserDetailsModal;
