import { Form, Input, Modal, Select, Spin } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import api from 'api';
import type { GuestShopData } from 'api/interface';
import { DetailsType } from 'global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { hasPermission } from 'utils';

interface ShopDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsType: DetailsType;
  data: GuestShopData;
}

const ShopDetailsModal: React.FC<ShopDetailsModalProps> = ({ open, setOpen, detailsType, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const [subGuestOptions, setSubGuestOptions] = useState<BaseOptionType[]>([]);
  const [subMemberOptions, setSubMemberOptions] = useState<BaseOptionType[]>([]);

  useEffect(() => {
    if (open) {
      if (hasPermission(['GUEST'])) {
        fetchChildGuests().then(() => {
          if (detailsType === DetailsType.CREATE) {
            form.resetFields();
          } else {
            form.setFieldsValue(data);
          }
        });
      } else {
        if (detailsType === DetailsType.CREATE) {
          form.resetFields();
        } else {
          form.setFieldsValue(data);
        }
      }
    }
  }, [open]);

  const fetchChildGuests = async () => {
    try {
      const res = await api.UserApi.getChildGuestList({ pageSize: 2147483647 });
      const subGuestOptions = res.data
        .filter(child => child.userRole === 'SUB-GUEST')
        .map(child => ({
          label: child.userEmail || '',
          value: child.userId || '',
        }));
      const subMemberOptions = res.data
        .filter(child => child.userRole === 'MEMBER')
        .map(child => ({
          label: child.userEmail || '',
          value: child.userId || '',
        }));

      setSubGuestOptions(subGuestOptions);
      setSubMemberOptions(subMemberOptions);
    } catch {
      console.error('getChildList failed');
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values: GuestShopData = await form.validateFields();
      if (detailsType === DetailsType.CREATE) {
        await api.ShopApi.createGuestShop(values);
      } else {
        await api.ShopApi.updateGuestShop({ ...values, shopId: data.shopId });
      }
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={detailsType === DetailsType.CREATE ? 'Create' : 'Edit'}
      width={800}
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText='Save'
      cancelText='Cancel'
      confirmLoading={loading}
    >
      <Form form={form} labelCol={{ span: 8 }}>
        <Form.Item label='Shopify Store ID' name='shopName' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Store ID Here...' />
        </Form.Item>
        <Form.Item label='Brand Name' name='brandName' rules={[{ required: true }]}>
          <Input placeholder='Please Input Brand Name Here...' />
        </Form.Item>
        <Form.Item label='Shopify Admin API Access Token' name='shopifyAccessToken' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Admin API Access Token Here...' />
        </Form.Item>
        <Form.Item label='Shopify API Key' name='shopifyAPIKey' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify API Key Here...' />
        </Form.Item>
        {hasPermission(['GUEST']) && (
          <>
            <Form.Item label='Sub Guest' name='subguestId' rules={[{ required: false }]}>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                placeholder='Please Select Sub-Guest'
                notFoundContent={loading ? <Spin size='small' /> : null}
                options={subGuestOptions}
              />
            </Form.Item>
            <Form.Item label='Member' name='submemberId' rules={[{ required: false }]}>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                placeholder='Please Select Member'
                notFoundContent={loading ? <Spin size='small' /> : null}
                options={subMemberOptions}
              />
            </Form.Item>
            <Form.Item label='+Product ¥ extra' name='rmbExtra' rules={[{ required: false }]}>
              <Input placeholder='Please Input Product $ extra' type='number' />
            </Form.Item>
            <Form.Item label='*Value to be multiplied (shipping)' name='valueToMultiplied' rules={[{ required: false }]}>
              <Input placeholder='Please Input Value to be multiplied (shipping)' type='number' />
            </Form.Item>
            <Form.Item label='+Fixed value (shipping)' name='fixedValueShipping' rules={[{ required: false }]}>
              <Input placeholder='Please Input Fixed value (shipping)' type='number' />
            </Form.Item>
            <Form.Item label='+Final extra (€)' name='finalExtraEur' rules={[{ required: false }]}>
              <Input placeholder='Please Input Final Extra(€)' type='number' />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ShopDetailsModal;
