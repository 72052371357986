import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

export const convertStringToDayjs = (value?: string): Dayjs | null => {
  if (isEmpty(value)) return null;

  return dayjs(value);
};

export const convertDayjsToString = (value: Dayjs | null) => {
  if (isEmpty(value)) return null;

  return dayjs(value).format('YYYY-MM-DD');
};

export const formatDateTime = (value?: string) => {
  if (isEmpty(value)) return '';

  return dayjs(value).format('YYYY-MM-DD hh:mm');
};

export const hasPermission = (permissions: string[]): boolean => {
  const authoritiesStr = localStorage.getItem('authorities');
  if (authoritiesStr) {
    const authorities: string[] = JSON.parse(authoritiesStr) as string[];
    if (permissions.filter(permission => authorities.includes(permission)).length === 0) return false;
    return true;
  }
  return false;
};
