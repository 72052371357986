import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import styles from './styles.module.css';

const MainHeaderBar = () => {
  const { sidebarCollapsed } = useSelector((state: RootState) => state.dropFit);
  const dispatch = useDispatch<Dispatch>();

  const handleCollapse = () => {
    dispatch.dropFit.reverseSidebarCollapsed();
  };

  const todayDateStr = useMemo(() => {
    const date = dayjs();
    const dateOfWeek = ['Saturday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return `${date.format('YYYY-MM-DD')} ${dateOfWeek[date.day()]}`;
  }, []);

  return (
    <div className={styles['main-header-bar']}>
      <Button type='primary' onClick={handleCollapse}>
        {sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <div className={styles['date']}>{todayDateStr}</div>
    </div>
  );
};

export default MainHeaderBar;
