import { Button, Image, Modal, Space } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import api from 'api';
import { GuestApplicationData } from 'api/interface';
import { DynamicTable, PageHeader } from 'components';
import { DetailsType, ExtBtnType } from 'global';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, RootState } from 'store';
import { initialFetch } from 'store/models/dropFit';
import { hasPermission } from 'utils';

import { SearchItemType } from '../../../components/FilterBar';
import ApplicationDetailsModal from './ApplicationDetailsModal';
import ApplicationShopifyModal from './ApplicationShopifyModal';

const GuestApplications = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useSelector((state: RootState) => state.dropFit);
  const { loading } = useSelector((state: RootState) => state.loading.effects.dropFit.searchGuestApplications);
  const dispatch = useDispatch<Dispatch>();
  const [openModal, setOpenModal] = useState(false);
  const [openShopifyModal, setOpenShopifyModal] = useState(false);
  const [detailsType, setDetailsType] = useState<DetailsType>(DetailsType.CREATE);
  const [formData, setFormData] = useState<GuestApplicationData>({ shopId: id });
  const [shopList, setShopList] = useState<BaseOptionType[]>([]);

  useEffect(() => {
    api.ShopApi.getGuestShopList().then(res => {
      const shopList = res.data.map(child => ({
        label: child.brandName,
        value: child.shopId,
      }));
      setShopList(shopList);
    });
  }, []);

  const handleCreate = () => {
    setDetailsType(DetailsType.CREATE);
    setOpenModal(true);
  };

  const handleEdit = (data: GuestApplicationData) => {
    setFormData({ ...data, shopId: id });
    setDetailsType(DetailsType.EDIT);
    setOpenModal(true);
  };

  const handleSetShopifyProductId = (data: GuestApplicationData) => {
    setFormData({ ...data, shopId: id });
    setOpenShopifyModal(true);
  };

  const handleDelete = (applicationId?: string) => {
    if (applicationId == undefined || applicationId === '') {
      return;
    }
    Modal.confirm({
      title: 'Delete Application',
      content: 'Are you confirm to delete? This can not be undone!',
      icon: null,
      okText: 'OK',
      cancelText: 'Cancel',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        await api.ApplicationApi.deleteApplication(applicationId);
        dispatch.dropFit.reloadTable(true);
      },
    });
  };

  const columns: ColumnsType<GuestApplicationData> = [
    {
      title: 'Image',
      align: 'center',
      dataIndex: 'applicationImage',
      render: (applicationImage: string) => (
        <>
          <Image src={applicationImage} style={{ width: 'auto', height: '100px' }} />
        </>
      ),
    },
    { title: 'Product URL', align: 'left', dataIndex: 'aliexpressUrl', width: 200 },
    { title: 'Price(¥)', align: 'center', dataIndex: 'rmbPrice' },
    { title: 'Weight(Kg)', align: 'center', dataIndex: 'weight' },
    { title: '1688 URL', align: 'left', dataIndex: 'alibabaUrl', width: 200 },
  ];
  if (hasPermission(['MEMBER']) || hasPermission(['GUEST'])) {
    columns.push(
      { title: 'Cog1', align: 'center', dataIndex: 'cog1' },
      { title: 'Cog2', align: 'center', dataIndex: 'cog2' },
      { title: 'Cog3', align: 'center', dataIndex: 'cog3' },
      { title: 'Cog4', align: 'center', dataIndex: 'cog4' },
      { title: 'Cog5', align: 'center', dataIndex: 'cog5' },
    );
  }
  columns.push(
    {
      title: 'Brand Name',
      align: 'center',
      render: (data: GuestApplicationData) => <p>{data.shop?.brandName}</p>,
    },
    {
      title: 'Shop Name',
      align: 'center',
      render: (data: GuestApplicationData) => <p>{data.shop?.shopName}</p>,
    },
    {
      title: 'Operations',
      align: 'center',
      render: (data: GuestApplicationData) => (
        <Space direction='vertical'>
          {hasPermission(['MEMBER']) && data.updated == null && (
            <Button type='link' onClick={() => handleDelete(data.applicationId)}>
              Delete Application
            </Button>
          )}
          {(hasPermission(['SUB-GUEST']) || hasPermission(['GUEST'])) && (
            <Button type='link' onClick={() => handleEdit(data)}>
              Write Weight Info
            </Button>
          )}
          {hasPermission(['GUEST']) && data.updated && (
            <Button type='link' onClick={() => handleSetShopifyProductId(data)}>
              Set Shopify Product Id
            </Button>
          )}
        </Space>
      ),
    },
  );

  return (
    <>
      <PageHeader title='Application List' extBtnType={ExtBtnType.CREATE} extBtnAction={handleCreate} createPermission={['MEMBER']} />

      <DynamicTable
        columns={columns}
        initialContext={
          id === search?.campaignsFetchParams?.fixed?.shopId
            ? search?.campaignsFetchParams
            : {
                ...initialFetch,
                fixed: { shopId: id },
              }
        }
        scroll={{
          x: 2000,
        }}
        fetch={dispatch.dropFit.searchGuestApplications}
        dataSource={search?.searchContext?.rows}
        searchItems={
          hasPermission(['GUEST']) || hasPermission(['SUB-GUEST'])
            ? [
                {
                  label: 'Weight Confirm Status',
                  type: SearchItemType.SELECT,
                  key1: 'status',
                  options: [
                    { label: 'ALL', value: '' },
                    { label: 'YES', value: 'true' },
                    { label: 'NO', value: 'false' },
                  ],
                },
                {
                  label: 'Brand Name',
                  type: SearchItemType.SELECT,
                  key1: 'shopId',
                  options: shopList,
                },
              ]
            : [
                {
                  label: 'Weight Confirm Status',
                  type: SearchItemType.SELECT,
                  key1: 'status',
                  options: [
                    { label: 'ALL', value: '' },
                    { label: 'YES', value: 'true' },
                    { label: 'NO', value: 'false' },
                  ],
                },
              ]
        }
        totalCount={search?.searchContext?.totalCount}
        rowKey='applicationId'
        loading={loading || false}
      />
      <ApplicationDetailsModal open={openModal} setOpen={setOpenModal} detailsType={detailsType} data={formData} />
      <ApplicationShopifyModal open={openShopifyModal} setOpen={setOpenShopifyModal} data={formData} />
    </>
  );
};

export default GuestApplications;
