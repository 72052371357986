import { Dayjs } from 'dayjs';

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

export enum DetailsType {
  CREATE,
  EDIT,
}

export enum ExtBtnType {
  CREATE,
  BACK,
}
