import { Business, SettingsApplications } from '@mui/icons-material';
import { Layout, Menu, MenuProps } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from 'utils';

import { RootState } from '../../store';
import AccountInfo from './AccountInfo';
import Logo from './Logo';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
};

const MainSideBar = () => {
  const navigate = useNavigate();
  const { sidebarCollapsed } = useSelector((state: RootState) => state.dropFit);

  const getMenuItems = useMemo(() => {
    const menuItems: MenuItem[] = [];

    // Main Board
    const mainBoardMenuItems: MenuItem[] = [];
    if (hasPermission(['GUEST']) || hasPermission(['MEMBER'])) mainBoardMenuItems.push(getItem('Shop List', 'guestShops'));
    if (hasPermission(['GUEST']) || hasPermission(['SUB-GUEST'])) mainBoardMenuItems.push(getItem('Application List', 'guestApplications'));

    if (hasPermission(['ADMIN']) || hasPermission(['USER']) || hasPermission(['CHILD']))
      mainBoardMenuItems.push(getItem('Shop List', 'shops'));
    if (hasPermission(['ADMIN']) || hasPermission(['USER'])) mainBoardMenuItems.push(getItem('Rule List', 'rules'));
    menuItems.push(getItem('Main Board', 'main', <Business />, mainBoardMenuItems));

    // Account Management
    const accountManagementMenuItems: MenuItem[] = [];
    if (hasPermission(['ADMIN'])) accountManagementMenuItems.push(getItem('User', 'members'));
    if (hasPermission(['ADMIN']) || hasPermission(['USER'])) accountManagementMenuItems.push(getItem('Sub Accounts', 'sub-accounts'));
    if (hasPermission(['GUEST'])) accountManagementMenuItems.push(getItem('Sub Guests and Members', 'childGuests'));
    accountManagementMenuItems.push(getItem('Password', 'change-password'));
    if (hasPermission(['ADMIN']) || hasPermission(['USER'])) accountManagementMenuItems.push(getItem('LINE', 'line'));
    menuItems.push(getItem('Account Management', 'settings', <SettingsApplications />, accountManagementMenuItems));

    // Shop Statistics
    const shopStatisticsMenuItems: MenuItem[] = [];
    if (hasPermission(['ADMIN']) || hasPermission(['CONNECTED']))
      shopStatisticsMenuItems.push(getItem('Connected Shops', 'connected-shops'));
    if (hasPermission(['ADMIN']) || hasPermission(['CONNECTED']))
      menuItems.push(getItem('Shop Statistics', 'statistics', <Business />, shopStatisticsMenuItems));

    return menuItems;
  }, []);

  const handleClickMenu = ({ keyPath }: MenuInfo) => {
    const path = `/${keyPath.reverse().join('/')}`;
    navigate(path);
  };

  return (
    <Layout.Sider collapsed={sidebarCollapsed}>
      <Logo />
      <AccountInfo />
      <Menu theme='dark' color='red' mode='inline' items={getMenuItems} onClick={handleClickMenu} />
    </Layout.Sider>
  );
};

export default MainSideBar;
