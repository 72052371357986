import { AxiosPromise } from 'axios/index';

import { BaseApi, RequestArgs } from './base';
import { createRequestFunction } from './common';
import { ConnectedShopData, ConnectedShopsFetchParams, GuestShopData, ShopData } from './interface';

export default class ShopApi extends BaseApi {
  getConnectedShopList(params: ConnectedShopsFetchParams): AxiosPromise<ConnectedShopData[]> {
    const { search } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/connectedShop/getList`,
      options: {
        method: 'GET',
        params: {
          ...search,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getUserList(): AxiosPromise<string[]> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/connectedShop/getUserList`,
      options: {
        method: 'GET',
      },
    };
    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getGuestShopList(): AxiosPromise<GuestShopData[]> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestShop/getList`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getShopList(): AxiosPromise<ShopData[]> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/shop/getList`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getShopById(shopId: string): AxiosPromise<ShopData> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/shop/getById`,
      options: {
        method: 'GET',
        params: {
          shopId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createConnectedShop(data: ConnectedShopData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/connectedShop/create`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createGuestShop(data: GuestShopData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestShop/create`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createShop(data: ShopData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/shop/create`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateConnectedShop(data: ConnectedShopData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/connectedShop/update`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateGuestShop(data: GuestShopData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestShop/update`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateShop(data: ShopData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/shop/update`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteConnectedShop(shopId: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/connectedShop/delete`,
      options: {
        method: 'POST',
        params: {
          shopId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteGuestShop(shopId: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/guestShop/delete`,
      options: {
        method: 'POST',
        params: {
          shopId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteShop(shopId: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/shop/delete`,
      options: {
        method: 'POST',
        params: {
          shopId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
