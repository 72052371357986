/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigProvider, Pagination, Table } from 'antd';
import type { TableProps } from 'antd/es/table';
import { FetchArguments } from 'api/interface';
import FilterBar, { SearchItem } from 'components/FilterBar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import styles from './styles.module.css';

export interface DynamicTableContext {
  totalCount?: number;
  rows?: any[];
}

interface DynamicTableProps<RecordType> extends TableProps<RecordType> {
  fetch: (fetchParams: any) => Promise<void>;
  initialContext?: FetchArguments;
  searchItems?: SearchItem[];
  totalCount?: number;
  loading: boolean;
}

const DynamicTable = <RecordType extends object = any>({
  initialContext,
  fetch,
  searchItems,
  totalCount,
  pagination,
  loading,
  ...props
}: DynamicTableProps<RecordType>) => {
  const [currentPage, setCurrentPage] = useState(initialContext?.currentPage);
  const [pageSize, setPageSize] = useState(initialContext?.pageSize);
  const [filters, setFilters] = useState(initialContext?.search || {});
  const reloadTable = useSelector((state: RootState) => state.dropFit.reloadTable);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.dropFit.initSearch();

    return () => {
      dispatch.dropFit.initSearch();
    };
  }, []);

  useEffect(() => {
    dispatch.dropFit.loadingPage(loading);
  }, [loading]);

  useEffect(() => {
    loadData();
  }, [currentPage, pageSize, filters]);

  const searchParams: any = {
    currentPage,
    pageSize,
    fixed: {
      ...initialContext?.fixed,
    },
    search: {
      ...filters,
    },
  };

  useEffect(() => {
    if (reloadTable) {
      loadData();
      dispatch.dropFit.reloadTable(false);
    }
  }, [reloadTable]);
  const loadData = () => {
    fetch(searchParams);
  };

  const handleChangePagination = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      {Array.isArray(searchItems) && searchItems.length !== 0 && (
        <FilterBar searchItems={searchItems} initialFilters={filters} setNewFilters={setFilters} />
      )}
      <div className='page-content'>
        <div className={styles['total-count']}>Counts：{totalCount}</div>
        <ConfigProvider theme={{ token: { borderRadius: 0 } }}>
          <Table locale={{ emptyText: 'No Data' }} bordered={true} {...props} pagination={false} />
        </ConfigProvider>
        {totalCount !== 0 && pagination !== false && (
          <div className={styles['pagination-content']}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalCount}
              pageSizeOptions={[20, 50, 100]}
              onChange={handleChangePagination}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DynamicTable;
