import { Layout, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import MainHeaderBar from '../MainHeaderBar';
import MainSideBar from '../MainSideBar';
import styles from './styles.module.css';

const MainLayout: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const { loadingPage } = useSelector((state: RootState) => state.dropFit);

  return (
    <Spin size='large' spinning={loadingPage}>
      <Layout style={{ height: '100vh' }}>
        <MainSideBar />
        <Layout.Content className={styles['layout-content']}>
          <MainHeaderBar />
          <div className={styles['main-layout-content']}>
            <div className={styles['page-content']}>{children}</div>
          </div>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default MainLayout;
