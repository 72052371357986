import { AxiosPromise } from 'axios';

import { BaseApi, RequestArgs } from './base';
import { createRequestFunction } from './common';
import type { LoginFetchParams, LoginResponse } from './interface';

export default class AuthApi extends BaseApi {
  login(params: LoginFetchParams): AxiosPromise<LoginResponse> {
    const localVarAxiosArgs: RequestArgs = {
      url: `/auth/login`,
      options: {
        method: 'POST',
        params: params,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
