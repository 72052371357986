import { AxiosPromise } from 'axios/index';

import { BaseApi, RequestArgs } from './base';
import { createRequestFunction } from './common';
import { RuleData, RulesFetchParams } from './interface';

export default class RuleApi extends BaseApi {
  getRuleList(params: RulesFetchParams): AxiosPromise<RuleData[]> {
    const { search } = params;
    const localVarAxiosArgs: RequestArgs = {
      url: `/rule/getList`,
      options: {
        method: 'GET',
        params: {
          ...search,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  createRule(data: RuleData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/rule/create`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateRule(data: RuleData) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/rule/update`,
      options: {
        method: 'POST',
        params: {
          ruleId: data.ruleId,
        },
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteRule(ruleId: string) {
    const localVarAxiosArgs: RequestArgs = {
      url: `/rule/delete`,
      options: {
        method: 'POST',
        params: {
          ruleId,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
