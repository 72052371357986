import { ConnectedShops } from 'pages';
import { Route, Routes } from 'react-router-dom';

const Statistics = () => (
  <Routes>
    <Route path='/connected-shops' element={<ConnectedShops />} />
  </Routes>
);

export default Statistics;
