import { Button, ConfigProvider } from 'antd';
import { ExtBtnType } from 'global';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from 'utils';

import styles from './styles.module.css';

interface PageHeaderProps {
  title: string;
  extBtnType?: ExtBtnType;
  extBtnLink?: string;
  extBtnAction?: () => void;
  createPermission?: string[];
}

const PageHeader = ({ title, extBtnType, extBtnLink, extBtnAction, createPermission }: PageHeaderProps) => {
  const navigate = useNavigate();

  const handleExtBtn = () => {
    switch (extBtnType) {
      case ExtBtnType.CREATE:
        if (extBtnLink) navigate(extBtnLink);
        if (extBtnAction) extBtnAction();
        break;
      case ExtBtnType.BACK:
        navigate(-1);
        break;
    }
  };

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#00baad', controlHeight: 40 } }}>
      <div className={styles['page-header-content']}>
        <span className={styles['title']}>{title}</span>
        {extBtnType === ExtBtnType.CREATE && (!createPermission || hasPermission(createPermission)) && (
          <div className={styles['ext-content']} onClick={handleExtBtn}>
            <Button type='primary' className={styles['ext-btn']}>
              Create
            </Button>
          </div>
        )}
        {extBtnType === ExtBtnType.BACK && (
          <div className={styles['ext-content']} onClick={handleExtBtn}>
            <Button type='primary' className={styles['ext-btn']}>
              Back
            </Button>
          </div>
        )}
      </div>
    </ConfigProvider>
  );
};

export default PageHeader;
