import { ConfigProvider, Input, message } from 'antd';
import api from 'api';
import { PageFooter, PageHeader } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cnewPassword, setCNewPassword] = useState('');
  const dispatch = useDispatch<Dispatch>();

  const handleSubmit = async () => {
    if (!(newPassword === cnewPassword)) {
      message.error('two new password not matched!');
      return;
    }
    dispatch.dropFit.loadingPage(true);

    try {
      await api.UserApi.changePassword(oldPassword, newPassword);
      message.success({
        content: 'Change Password Success!',
      });
    } catch (err) {
      console.log(err);
    }

    dispatch.dropFit.loadingPage(false);
  };

  return (
    <>
      <PageHeader title='Change Password' />

      <div className='page-content'>
        <ConfigProvider theme={{ token: { colorBorder: 'transparent', borderRadius: 0 } }}>
          <div className='grid-content grid-3'>
            <div className='grid-item'>
              <div className='label'>*Old</div>
              <Input.Password
                placeholder='Please Input Old Password'
                value={oldPassword}
                onChange={e => setOldPassword(e.currentTarget.value)}
              />
            </div>
            <div className='grid-item'>
              <div className='label'>*New</div>
              <Input.Password
                placeholder='Please Input New Password Here'
                value={newPassword}
                onChange={e => setNewPassword(e.currentTarget.value)}
              />
            </div>
            <div className='grid-item'>
              <div className='label'>*New Again</div>
              <Input.Password
                placeholder='Please Input New Password Again Here'
                value={cnewPassword}
                onChange={e => setCNewPassword(e.currentTarget.value)}
              />
            </div>
          </div>
        </ConfigProvider>
      </div>

      <PageFooter onOk={handleSubmit} />
    </>
  );
};

export default ChangePassword;
