import { DatePicker, Modal, Spin, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import { ColumnsType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import api from '../../../api';
import { CampaignStatisticsDetailData } from '../../../api/interface';

interface CampaignStatisticsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  campaignId: string;
  campaignName: string;
}

const CampaignStatisticsModal: React.FC<CampaignStatisticsModalProps> = ({ open, setOpen, campaignId, campaignName }) => {
  const [data, setData] = useState<CampaignStatisticsDetailData[]>();
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();

  useEffect(() => {
    if (open && campaignId !== '') {
      setData([]);
      setLoading(true);
      api.CampaignApi.getCampaignById(campaignId)
        .then(res => {
          const data = [];
          data.push({ ...res.data.statistics, title: 'Today' });
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open]);

  const handleChangeDateRange = (value: [Dayjs | null, Dayjs | null] | null) => {
    if (value != undefined && value[0] != undefined && value[1] != undefined) {
      const from = dayjs(value[0]).format('YYYY-MM-DD');
      const to = dayjs(value[1]).format('YYYY-MM-DD');
      setFrom(from);
      setTo(to);
    } else {
      setFrom(undefined);
      setTo(undefined);
    }
  };

  const handleFilter = () => {
    setLoading(true);
    api.CampaignApi.getCampaignById(campaignId, from, to)
      .then(res => {
        const data = [];
        data.push({ ...res.data.statistics, title: from && to ? `${from}~${to}` : 'Today' });
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFrom(undefined);
    setTo(undefined);
    setOpen(false);
  };

  const columns: ColumnsType<CampaignStatisticsDetailData> = [
    { title: '', align: 'center', dataIndex: 'title' },
    { title: 'Revenue', align: 'center', dataIndex: 'revenue' },
    { title: 'Profit', align: 'center', dataIndex: 'profit' },
    { title: 'VAT', align: 'center', dataIndex: 'vat' },
    { title: 'Total COGS', align: 'center', dataIndex: 'cog' },
    { title: 'ROAS(%)', align: 'center', dataIndex: 'roas_percent' },
    { title: 'PROFIT MARGIN(%)', align: 'center', dataIndex: 'profit_margin_percent' },
    { title: 'CPA', align: 'center', dataIndex: 'cpa' },
    { title: 'Ad Spend(€)', align: 'center', dataIndex: 'adSpendEUR' },
    { title: 'Ad Spend($)', align: 'center', dataIndex: 'adSpendUSD' },
    { title: 'Order Count', align: 'center', dataIndex: 'orders' },
  ];

  return (
    <Modal
      width={1200}
      title={`Campaign Statistics : ${campaignName}`}
      open={open}
      onCancel={handleClose}
      cancelText='Close'
      okText='Filter'
      onOk={handleFilter}
    >
      <Spin size='large' spinning={loading}>
        <DatePicker.RangePicker
          locale={locale}
          value={from && to ? [dayjs(`${from}`), dayjs(`${to}`)] : null}
          onChange={value => handleChangeDateRange(value)}
        />
        <Table className='mt-10' columns={columns} dataSource={data} pagination={false} />
      </Spin>
    </Modal>
  );
};

export default CampaignStatisticsModal;
