import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, InputRef, Modal, Select, Space } from 'antd';
import api from 'api';
import type { CampaignData } from 'api/interface';
import { DetailsType } from 'global';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

interface CampaignDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shopId: string;
  detailsType: DetailsType;
  data: CampaignData;
}

const CampaignDetailsModal: React.FC<CampaignDetailsModalProps> = ({ open, setOpen, shopId, detailsType, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();
  const [adIdList, setAdIdList] = useState<string[]>([]);
  const [newAdId, setNewAdId] = useState('');
  const inputRef = useRef<InputRef>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      api.CampaignApi.getAdAccountList(shopId)
        .then(res => {
          setAdIdList(res.data);
        })
        .finally(() => {
          setLoading(false);
          if (detailsType === DetailsType.CREATE) {
            form.resetFields();
          } else {
            form.setFieldsValue(data);
          }
        });
    }
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values: CampaignData = await form.validateFields();
      if (detailsType === DetailsType.CREATE) {
        await api.CampaignApi.createCampaign({ ...values, shopId: data.shopId });
      } else {
        await api.CampaignApi.updateCampaign({ ...values, shopId: data.shopId, campaignId: data.campaignId });
      }
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNewAdIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAdId(event.target.value);
  };

  const handleNewAdId = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setAdIdList([...adIdList, newAdId]);
    setNewAdId('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Modal
      title={detailsType === DetailsType.CREATE ? 'Create' : 'Edit'}
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText='Save'
      cancelText='Cancel'
      confirmLoading={loading}
    >
      <Form form={form} labelCol={{ span: 9 }}>
        <Form.Item label='Campaign Name' name='campaignName' rules={[{ required: true }]}>
          <Input placeholder='Please Input Campaign Name Here...' />
        </Form.Item>
        <Form.Item label='Shopify Product Id' name='shopifyProductId' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Product Id Here...' type='number' />
        </Form.Item>
        <Form.Item label='Platform' name='platform' rules={[{ required: true }]}>
          <Select
            size='large'
            placeholder='Please select platform here'
            options={[
              { label: 'FACEBOOK', value: 'FACEBOOK' },
              { label: 'PINTEREST', value: 'PINTEREST' },
            ]}
          />
        </Form.Item>
        <Form.Item label='Campaign Id' name='platformCampaignId' rules={[{ required: true }]}>
          <Input placeholder='Please Input Campaign Id Here...' type='number' />
        </Form.Item>
        <Form.Item label='Ad Account Id' name='platformAdAccountId' rules={[{ required: true }]}>
          <Select
            style={{ width: 300 }}
            placeholder='Please Select Ad Account Id Here...'
            dropdownRender={menu => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Input
                    placeholder='Please enter item'
                    ref={inputRef}
                    value={newAdId}
                    onChange={handleNewAdIdChange}
                    onKeyDown={e => e.stopPropagation()}
                  />
                  <Button type='text' icon={<PlusOutlined />} onClick={handleNewAdId}>
                    Add item
                  </Button>
                </Space>
              </>
            )}
            options={adIdList.map(item => ({ label: item, value: item }))}
          />
        </Form.Item>
        <Form.Item label='Cog1' name='cog1' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog1 Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog2' name='cog2' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog2 Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog3' name='cog3' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog3 Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog4' name='cog4' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog4 Here...' type='number' />
        </Form.Item>
        <Form.Item label='Cog5' name='cog5' rules={[{ required: true }]}>
          <Input placeholder='Please Input Cog5 Here...' type='number' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CampaignDetailsModal;
