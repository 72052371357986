import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { UserData } from 'api/interface';
import DynamicTable from 'components/DynamicTable';
import { SearchItemType } from 'components/FilterBar';
import PageHeader from 'components/PageHeader';
import { DetailsType, ExtBtnType } from 'global';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { formatDateTime } from 'utils';

import UserDetailsModal from './UserDetailsModal';

const SubGuest = () => {
  const { search } = useSelector((state: RootState) => state.dropFit);
  const { loading } = useSelector((state: RootState) => state.loading.effects.dropFit.searchChildGuests);
  const dispatch = useDispatch<Dispatch>();
  const [openModal, setOpenModal] = useState(false);
  const [detailsType, setDetailsType] = useState<DetailsType>(DetailsType.CREATE);
  const [formData, setFormData] = useState<UserData>({});

  const handleCreate = () => {
    setDetailsType(DetailsType.CREATE);
    setOpenModal(true);
  };

  const handleEdit = (data: UserData) => {
    setFormData(data);
    setDetailsType(DetailsType.EDIT);
    setOpenModal(true);
  };

  const columns: ColumnsType<UserData> = [
    { title: 'Email', align: 'left', dataIndex: 'userEmail' },
    { title: 'Role', align: 'center', dataIndex: 'userRole' },
    {
      title: 'Created Time',
      align: 'center',
      dataIndex: 'created',
      render: created => formatDateTime(created),
    },
    {
      title: 'Operations',
      align: 'center',
      width: 250,
      render: (data: UserData) => (
        <>
          <Button type='link' onClick={() => handleEdit(data)}>
            Reset Password
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title='Sub Guest and Member Management'
        extBtnType={ExtBtnType.CREATE}
        extBtnAction={handleCreate}
        createPermission={['GUEST']}
      />

      <DynamicTable
        columns={columns}
        fetch={dispatch.dropFit.searchChildGuests}
        initialContext={search?.usersFetchParams}
        dataSource={search?.searchContext?.rows}
        searchItems={[
          { label: 'Email', type: SearchItemType.STRING, key1: 'userEmail' },
          {
            label: 'Role',
            type: SearchItemType.SELECT,
            options: [
              { label: 'ALL', value: '' },
              { label: 'SUB-GUEST', value: 'SUB-GUEST' },
              { label: 'MEMBER', value: 'MEMBER' },
            ],
            key1: 'userRole',
          },
        ]}
        totalCount={search?.searchContext?.totalCount}
        loading={loading || false}
        rowKey='userId'
      />

      <UserDetailsModal open={openModal} setOpen={setOpenModal} detailsType={detailsType} data={formData} />
    </>
  );
};

export default SubGuest;
