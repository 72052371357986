import { Button, ConfigProvider } from 'antd';

import styles from './styles.module.css';

interface PageFooterProps {
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

const PageFooter: React.FC<PageFooterProps> = ({ okText = 'Save', cancelText = 'Cancel', onOk, onCancel, onDelete }) => {
  return (
    <ConfigProvider theme={{ token: { controlHeight: 35 } }}>
      <div className={styles['page-footer']}>
        {onOk && (
          <Button type='primary' onClick={onOk}>
            {okText}
          </Button>
        )}
        {onCancel && (
          <ConfigProvider theme={{ token: { colorPrimary: '#a6a6a6' } }}>
            <Button type='primary' onClick={onCancel}>
              {cancelText}
            </Button>
          </ConfigProvider>
        )}
        {onDelete && (
          <Button type='primary' danger onClick={onDelete}>
            删除
          </Button>
        )}
      </div>
    </ConfigProvider>
  );
};

export default PageFooter;
