import { Button, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CampaignData } from 'api/interface';
import { DynamicTable, PageHeader } from 'components';
import { DetailsType, ExtBtnType } from 'global';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, RootState } from 'store';
import { initialFetch } from 'store/models/dropFit';

import api from '../../../api';
import { SearchItemType } from '../../../components/FilterBar';
import CampaignDetailsModal from './CampaignDetailsModal';
import CampaignStatisticsModal from './CampaignStatisticsModal';

const Campaigns = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useSelector((state: RootState) => state.dropFit);
  const { loading } = useSelector((state: RootState) => state.loading.effects.dropFit.searchCampaigns);
  const dispatch = useDispatch<Dispatch>();
  const [openModal, setOpenModal] = useState(false);
  const [openStatisticsModal, setOpenStatisticsModal] = useState(false);
  const [campaignId, setCampaignId] = useState<string>('');
  const [campaignName, setCampaignName] = useState<string>('');
  const [detailsType, setDetailsType] = useState<DetailsType>(DetailsType.CREATE);
  const [formData, setFormData] = useState<CampaignData>({ shopId: id });

  const handleCreate = () => {
    setDetailsType(DetailsType.CREATE);
    setOpenModal(true);
  };

  const handleEdit = (data: CampaignData) => {
    setFormData({ ...data, shopId: id, campaignId: data.campaignId });
    setDetailsType(DetailsType.EDIT);
    setOpenModal(true);
  };

  const handleCheckStatistics = (campaignId?: string, campaignName?: string) => {
    if (campaignId == undefined || campaignId === '') {
      return;
    }
    setCampaignId(campaignId);
    setCampaignName(campaignName || '');
    setOpenStatisticsModal(true);
  };

  const handleDelete = (campaignId?: string) => {
    if (campaignId == undefined || campaignId === '') {
      return;
    }
    Modal.confirm({
      title: 'Delete Campaign',
      content: 'Are you confirm to delete? This can not be undone!',
      icon: null,
      okText: 'OK',
      cancelText: 'Cancel',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        await api.CampaignApi.deleteCampaign(campaignId);
        dispatch.dropFit.reloadTable(true);
      },
    });
  };

  const columns: ColumnsType<CampaignData> = [
    { title: 'Platform', align: 'center', dataIndex: 'platform' },
    { title: 'Campaign Name', align: 'center', dataIndex: 'campaignName' },
    { title: 'Shopify Product Id', align: 'center', dataIndex: 'shopifyProductId' },
    { title: 'Campaign Id', align: 'center', dataIndex: 'platformCampaignId' },
    { title: 'cog1', align: 'center', dataIndex: 'cog1' },
    { title: 'cog2', align: 'center', dataIndex: 'cog2' },
    { title: 'cog3', align: 'center', dataIndex: 'cog3' },
    { title: 'cog4', align: 'center', dataIndex: 'cog4' },
    { title: 'cog5', align: 'center', dataIndex: 'cog5' },
    {
      title: 'Operations',
      align: 'center',
      render: (data: CampaignData) => (
        <>
          <Button type='link' onClick={() => handleEdit(data)}>
            Edit Campaign
          </Button>
          <Button type='link' onClick={() => handleCheckStatistics(data.campaignId, data.campaignName)}>
            Check Statistics
          </Button>
          <Button type='link' onClick={() => handleDelete(data.campaignId)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Campaign List (€)' extBtnType={ExtBtnType.CREATE} extBtnAction={handleCreate} />

      <DynamicTable
        columns={columns}
        initialContext={
          id === search?.campaignsFetchParams?.fixed?.shopId
            ? search?.campaignsFetchParams
            : {
                ...initialFetch,
                fixed: { shopId: id },
              }
        }
        fetch={dispatch.dropFit.searchCampaigns}
        dataSource={search?.searchContext?.rows}
        searchItems={[
          {
            label: 'Platform',
            type: SearchItemType.SELECT,
            options: [
              { label: 'FACEBOOK', value: 'FACEBOOK' },
              { label: 'PINTEREST', value: 'PINTEREST' },
            ],
            key1: 'platform',
          },
          {
            label: 'Campaign Name',
            type: SearchItemType.STRING,
            key1: 'campaignName',
          },
        ]}
        totalCount={search?.searchContext?.totalCount}
        rowKey='campaignId'
        loading={loading || false}
      />
      <CampaignDetailsModal open={openModal} setOpen={setOpenModal} shopId={id || ''} detailsType={detailsType} data={formData} />
      <CampaignStatisticsModal
        open={openStatisticsModal}
        setOpen={setOpenStatisticsModal}
        campaignId={campaignId}
        campaignName={campaignName}
      />
    </>
  );
};

export default Campaigns;
