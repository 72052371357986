import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Input, InputNumber, message, Modal, Select, TimePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import api from 'api';
import type { RuleAction, RuleConditionData, RuleFrequency } from 'api/interface';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { DetailsType } from 'global';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Dispatch } from '../../../store';
import styles from './styles.module.css';

interface RuleDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsType: DetailsType;
  ruleId?: string;
  ruleName?: string;
  setRuleName: React.Dispatch<React.SetStateAction<string | undefined>>;
  rulePlatform?: string;
  setRulePlatform: React.Dispatch<React.SetStateAction<string | undefined>>;
  ruleConditions: RuleConditionData[];
  setRuleConditions: React.Dispatch<React.SetStateAction<RuleConditionData[]>>;
  ruleAction?: RuleAction;
  setRuleAction: React.Dispatch<React.SetStateAction<RuleAction>>;
  ruleFrequencies: RuleFrequency[];
  setRuleFrequencies: React.Dispatch<React.SetStateAction<RuleFrequency[]>>;
}

const RuleDetailsModal: React.FC<RuleDetailsModalProps> = ({
  open,
  setOpen,
  detailsType,
  ruleId,
  ruleName,
  setRuleName,
  rulePlatform,
  setRulePlatform,
  ruleConditions,
  setRuleConditions,
  ruleAction,
  setRuleAction,
  ruleFrequencies,
  setRuleFrequencies,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (detailsType === DetailsType.CREATE) {
        console.log('CREATE');
      } else {
        console.log(ruleFrequencies);
      }
    }
  }, [open]);

  const handleCancel = () => {
    const newData: RuleConditionData[] = [];
    setRuleName(undefined);
    setRulePlatform(undefined);
    setRuleConditions(newData);
    setRuleAction({});
    setRuleFrequencies([]);
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Rule Name Validation
      if (ruleName == undefined || ruleName === '') {
        message.error('Please input correct rule name');
        return;
      }

      if (rulePlatform == undefined || rulePlatform === '') {
        message.error('Please select rule platform');
        return;
      }

      // Rule Frequency Validation
      if (ruleFrequencies.length === 0) {
        message.error('Please input correct rule frequency');
        return;
      }
      for (let i = 0; i < ruleFrequencies.length; i++) {
        const frequency = ruleFrequencies[i];
        if (frequency.mode == undefined || frequency.mode === '') {
          message.error('Please input correct rule frequency');
          return;
        }
        if (frequency.mode !== 'EVERY_MINUTE' && (frequency.minute == undefined || frequency.hour == undefined)) {
          message.error('Please input correct rule frequency');
          return;
        }
      }

      // Rule Action Validation
      if (ruleAction == undefined || ruleAction.action == undefined || ruleAction.action === '') {
        message.error('Please input correct rule action');
        return;
      } else if (ruleAction.action !== 'STOP' && ruleAction.action !== 'ACTIVE' && ruleAction.value == undefined) {
        message.error('Please input correct rule action');
        return;
      }

      // Rule Condition Validation
      if (ruleConditions.length === 0) {
        message.error('Please input at least one rule');
        return;
      }
      for (let i = 0; i < ruleConditions.length; i++) {
        const condition = ruleConditions[i];
        if (
          condition.when == undefined ||
          condition.when === '' ||
          condition.what == undefined ||
          condition.what === '' ||
          condition.mode == undefined ||
          condition.mode === '' ||
          condition.value == undefined ||
          condition.valueType == undefined ||
          condition.valueType === ''
        ) {
          message.error('Please input correct rule condition');
          return;
        }
      }

      if (detailsType === DetailsType.CREATE) {
        await api.RuleApi.createRule({
          ruleName,
          platform: rulePlatform,
          ruleConditions,
          ruleAction,
          ruleFrequencies,
        });
      } else {
        await api.RuleApi.updateRule({
          ruleId,
          ruleName,
          platform: rulePlatform,
          ruleConditions,
          ruleAction,
          ruleFrequencies,
        });
      }
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeName = (value: string) => {
    setRuleName(value);
  };
  const handleChangePlatform = (value: string) => {
    setRulePlatform(value);
  };
  const handleChangeConditions = (index: number, key: keyof RuleConditionData, value: React.ReactNode) => {
    const newData: RuleConditionData[] = [];
    for (let i = 0; i < ruleConditions.length; i++) {
      if (i === index) {
        newData.push({ ...ruleConditions[index], [key]: value });
      } else {
        newData.push(ruleConditions[i]);
      }
    }
    if (newData[index].when !== 'TODAY' || (newData[index].what !== 'AD_SPEND_EUR' && newData[index].what !== 'AD_SPEND_USD')) {
      newData[index].valueType = 'FIXED';
    }
    setRuleConditions(newData);
  };

  const handleChangeAction = (key: keyof RuleAction, value: React.ReactNode) => {
    const newRuleAction = { ...ruleAction, [key]: value };
    if (key === 'action' && (value === 'STOP' || value === 'ACTIVE')) {
      newRuleAction.value = undefined;
      newRuleAction.target = undefined;
    }
    setRuleAction(newRuleAction);
  };

  const handleChangeFrequencyMode = (index: number, value: string) => {
    const newData: RuleFrequency[] = [];
    for (let i = 0; i < ruleFrequencies.length; i++) {
      if (i === index) {
        newData.push(value === 'EVERY_MINUTE' ? { mode: value } : { ...ruleFrequencies[index], mode: value });
      } else {
        newData.push(ruleFrequencies[i]);
      }
    }
    setRuleFrequencies(newData);
  };

  const handleChangeFrequencyHourAndMinute = (index: number, value: Dayjs | null) => {
    const newData: RuleFrequency[] = [];
    for (let i = 0; i < ruleFrequencies.length; i++) {
      if (i === index) {
        newData.push({ ...ruleFrequencies[i], hour: value?.hour(), minute: value?.minute() });
      } else {
        newData.push(ruleFrequencies[i]);
      }
    }
    setRuleFrequencies(newData);
  };
  const handleAddNewFrequency = () => {
    const newData: RuleFrequency[] = [];
    ruleFrequencies.map(ruleFrequency => {
      newData.push(ruleFrequency);
    });
    newData.push({});
    setRuleFrequencies(newData);
  };

  const handleRemoveFrequency = (index: number) => {
    ruleFrequencies.splice(index, 1);
    const newData: RuleFrequency[] = [];
    ruleFrequencies.map(ruleFrequency => {
      newData.push(ruleFrequency);
    });
    setRuleConditions(newData);
  };

  const handleAddNewRule = () => {
    const newData: RuleConditionData[] = [];
    ruleConditions.map(rule => {
      newData.push(rule);
    });
    newData.push({});
    setRuleConditions(newData);
  };

  const handleRemoveRule = (index: number) => {
    ruleConditions.splice(index, 1);
    const newData: RuleConditionData[] = [];
    ruleConditions.map(rule => {
      newData.push(rule);
    });
    setRuleConditions(newData);
  };

  return (
    <>
      <Modal
        title={detailsType === DetailsType.CREATE ? 'Create' : 'Edit'}
        open={open}
        width={800}
        onCancel={handleCancel}
        onOk={handleSubmit}
        okText='Save'
        cancelText='Cancel'
        confirmLoading={loading}
      >
        <div>Name</div>
        <Input placeholder='Please Input Rule Name Here...' value={ruleName} onChange={e => handleChangeName(e.currentTarget.value)} />

        <div className='mt-20'>Platform</div>
        <Select
          style={{ width: 200 }}
          placeholder='Please select platform here'
          options={[
            { label: 'FACEBOOK', value: 'FACEBOOK' },
            { label: 'PINTEREST', value: 'PINTEREST' },
          ]}
          value={rulePlatform}
          onChange={value => handleChangePlatform(value)}
        />

        <div className='mt-20'>Conditions</div>
        {ruleConditions.map((rule, index) => (
          <div key={index} className={styles['rule-line']}>
            <Select
              className='flex-1'
              placeholder='When'
              options={[
                { label: 'Today', value: 'TODAY' },
                { label: 'Yesterday', value: 'YESTERDAY' },
                { label: 'Last 3 Days', value: 'LAST_3_DAYS' },
                { label: 'Last 7 Days', value: 'LAST_7_DAYS' },
                { label: 'Last 30 Days', value: 'LAST_30_DAYS' },
                { label: 'This 7 Days (Including Today)', value: 'THIS_7_DAYS' },
                { label: 'This 30 Days (Including Today)', value: 'THIS_30_DAYS' },
              ]}
              value={rule.when}
              onChange={value => handleChangeConditions(index, 'when', value)}
            />
            <Select
              className='ml-10'
              style={{ width: 200 }}
              placeholder='What'
              options={[
                { label: 'Profit', value: 'PROFIT' },
                { label: 'CPA', value: 'CPA' },
                { label: 'Order Count', value: 'ORDERS' },
                { label: 'AD_SPEND(€)', value: 'AD_SPEND_EUR' },
                { label: 'AD_SPEND($)', value: 'AD_SPEND_USD' },
                { label: 'ROAS(%)', value: 'ROAS_PERCENT' },
                { label: 'PROFIT MARGIN(%)', value: 'PROFIT_MARGIN_PERCENT' },
                { label: 'CPC', value: 'CPC', disabled: true },
                { label: 'CTR(%)', value: 'CTR_PERCENT', disabled: true },
                { label: 'BID CAP', value: 'BID_CAP', disabled: true },
                { label: 'Add to Cart', value: 'ADD_TO_CART', disabled: true },
              ]}
              value={rule.what}
              onChange={value => handleChangeConditions(index, 'what', value)}
            />
            <Select
              className='flex-1 ml-10'
              placeholder='Mode'
              options={[
                { label: 'GREATER THAN', value: 'GREATER_THAN' },
                { label: 'EQUAL TO', value: 'EQUAL_TO' },
                { label: 'LESS THAN', value: 'LESS_THAN' },
              ]}
              value={rule.mode}
              onChange={value => handleChangeConditions(index, 'mode', value)}
            />
            <InputNumber
              className={cn(styles['rule-value-input'], 'ml-10')}
              placeholder='Value'
              value={rule.value}
              onChange={value => handleChangeConditions(index, 'value', value)}
            />
            <Select
              className='flex-1 ml-10'
              placeholder='Value Type'
              options={
                rule.when === 'TODAY' && (rule.what === 'AD_SPEND_EUR' || rule.what === 'AD_SPEND_USD')
                  ? [
                      { label: 'FIXED', value: 'FIXED' },
                      { label: 'OF BUDGET(%)', value: 'OF_BUDGET' },
                    ]
                  : [{ label: 'FIXED', value: 'FIXED' }]
              }
              value={rule.valueType}
              onChange={value => handleChangeConditions(index, 'valueType', value)}
            />
            <div className='ml-10' onClick={() => handleRemoveRule(index)}>
              <MinusCircleTwoTone />
            </div>
          </div>
        ))}
        <div onClick={handleAddNewRule}>
          <PlusCircleTwoTone />
        </div>

        <div className='mt-20'>Action</div>
        <div className={styles['action-rule']}>
          <Select
            style={{ width: 200 }}
            placeholder='Action'
            options={[
              { label: 'Stop', value: 'STOP' },
              { label: 'Active', value: 'ACTIVE' },
              { label: 'Increase (%)', value: 'INCREASE_PERCENT' },
              { label: 'Decrease (%)', value: 'DECREASE_PERCENT' },
              { label: 'Increase Fixed ($)', value: 'INCREASE_FIXED' },
              { label: 'Decrease Fixed ($)', value: 'DECREASE_FIXED' },
            ]}
            value={ruleAction?.action}
            onChange={value => handleChangeAction('action', value)}
          />
          <InputNumber
            className='ml-10'
            disabled={ruleAction?.action === 'STOP' || ruleAction?.action === 'ACTIVE'}
            placeholder='Value'
            value={ruleAction?.value}
            onChange={value => handleChangeAction('value', value)}
          />
          <Select
            className='ml-10'
            style={{ width: 200 }}
            disabled={ruleAction?.action === 'STOP' || ruleAction?.action === 'ACTIVE'}
            placeholder='What'
            options={[
              { label: 'Budget', value: 'BUDGET' },
              { label: 'BID CAP', value: 'BID_CAP', disabled: true },
            ]}
            value={ruleAction?.target}
            onChange={value => handleChangeAction('target', value)}
          />
        </div>

        <div className='mt-20'>When / How often needs to check</div>
        {ruleFrequencies.map((ruleFrequency, index) => (
          <div key={index} className={styles['rule-line']}>
            <Select
              style={{ width: 200 }}
              placeholder='Select Frequency'
              options={[
                { label: 'Every 10 minutes', value: 'EVERY_MINUTE' },
                { label: 'Everyday', value: 'EVERYDAY' },
                { label: 'Every Monday', value: 'MONDAY' },
                { label: 'Every Tuesday', value: 'TUESDAY' },
                { label: 'Every Wednesday', value: 'WEDNESDAY' },
                { label: 'Every Thursday', value: 'THURSDAY' },
                { label: 'Every Friday', value: 'FRIDAY' },
                { label: 'Every Saturday', value: 'SATURDAY' },
                { label: 'Every Sunday', value: 'SUNDAY' },
              ]}
              value={ruleFrequency?.mode}
              onChange={value => handleChangeFrequencyMode(index, value)}
            />
            <TimePicker
              disabled={ruleFrequency?.mode === 'EVERY_MINUTE'}
              className='ml-10'
              minuteStep={10}
              hourStep={1}
              format={'HH:mm'}
              placeholder='Time'
              showNow={false}
              locale={locale}
              value={
                ruleFrequency.hour != undefined &&
                ruleFrequency?.hour >= 0 &&
                ruleFrequency.minute != undefined &&
                ruleFrequency.minute >= 0
                  ? dayjs(`${ruleFrequency?.hour}:${ruleFrequency?.minute}`, 'HH:mm')
                  : undefined
              }
              onChange={value => handleChangeFrequencyHourAndMinute(index, value)}
            />
            <div className='ml-10' onClick={() => handleRemoveFrequency(index)}>
              <MinusCircleTwoTone />
            </div>
          </div>
        ))}
        <div onClick={handleAddNewFrequency}>
          <PlusCircleTwoTone />
        </div>
      </Modal>
    </>
  );
};

export default RuleDetailsModal;
