import { Form, Input, Modal, Select, Spin } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import api from 'api';
import type { ShopData } from 'api/interface';
import { DetailsType } from 'global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { hasPermission } from 'utils';

interface ShopDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailsType: DetailsType;
  data: ShopData;
}

const ShopDetailsModal: React.FC<ShopDetailsModalProps> = ({ open, setOpen, detailsType, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<BaseOptionType[]>([]);

  useEffect(() => {
    if (open) {
      if (hasPermission(['ADMIN']) || hasPermission(['USER'])) {
        fetchRules().then(newOptions => {
          setOptions(newOptions);
          if (detailsType === DetailsType.CREATE) {
            form.resetFields();
          } else {
            form.setFieldsValue(data);
          }
        });
      } else {
        if (detailsType === DetailsType.CREATE) {
          form.resetFields();
        } else {
          form.setFieldsValue(data);
        }
      }
    }
  }, [open]);

  const fetchRules = async () => {
    try {
      const res = await api.RuleApi.getRuleList({});
      const facebookOptions = res.data
        .filter(rule => rule.platform === 'FACEBOOK')
        .map(rule => ({
          label: rule.ruleName || '',
          value: rule.ruleId || '',
        }));
      const pinterestOptions = res.data
        .filter(rule => rule.platform === 'PINTEREST')
        .map(rule => ({
          label: rule.ruleName || '',
          value: rule.ruleId || '',
        }));

      return [
        { label: 'FACEBOOK', options: facebookOptions },
        { label: 'PINTEREST', options: pinterestOptions },
      ];
    } catch {
      return [];
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values: ShopData = await form.validateFields();
      if (detailsType === DetailsType.CREATE) {
        await api.ShopApi.createShop(values);
      } else {
        await api.ShopApi.updateShop({ ...values, shopId: data.shopId });
      }
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={detailsType === DetailsType.CREATE ? 'Create' : 'Edit'}
      width={800}
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText='Save'
      cancelText='Cancel'
      confirmLoading={loading}
    >
      <Form form={form} labelCol={{ span: 8 }}>
        <Form.Item label='Shopify Store ID' name='shopName' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Store ID Here...' />
        </Form.Item>
        <Form.Item label='Brand Name' name='brandName' rules={[{ required: true }]}>
          <Input placeholder='Please Input Brand Name Here...' />
        </Form.Item>
        <Form.Item label='VAT Rate' name='vatRate' rules={[{ required: true }]}>
          <Input placeholder='Please Input VAT Rate Here...' type='number' />
        </Form.Item>
        <Form.Item label='Shopify Admin API Access Token' name='shopifyAccessToken' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Admin API Access Token Here...' />
        </Form.Item>
        <Form.Item label='Shopify API Key' name='shopifyAPIKey' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify API Key Here...' />
        </Form.Item>
        <Form.Item label='Facebook Access Token' name='facebookAccessToken' rules={[{ required: true }]}>
          <Input placeholder='Please Input Facebook Access Token Here...' />
        </Form.Item>
        {(hasPermission(['ADMIN']) || hasPermission(['USER'])) && (
          <Form.Item label='Rule' name='ruleIds' rules={[{ required: false }]}>
            <Select
              mode='multiple'
              allowClear
              showSearch
              placeholder='Please Select Rule'
              notFoundContent={loading ? <Spin size='small' /> : null}
              options={options}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ShopDetailsModal;
