import { Form, Input, Modal } from 'antd';
import api from 'api';
import type { GuestApplicationData } from 'api/interface';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

interface ApplicationShopifyModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: GuestApplicationData;
}

const ApplicationShopifyModal: React.FC<ApplicationShopifyModalProps> = ({ open, setOpen, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      form.setFieldsValue(data);
    }
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values: GuestApplicationData = await form.validateFields();
      await api.ApplicationApi.setShopifyProductId({ ...values, applicationId: data.applicationId });
      handleCancel();
      dispatch.dropFit.reloadTable(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title='Edit Shopify Product Id'
      open={open}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText='Save'
      cancelText='Cancel'
      confirmLoading={loading}
    >
      <Form form={form} labelCol={{ span: 9 }}>
        <Form.Item label='Shopify Product Id' name='shopifyProductId' rules={[{ required: true }]}>
          <Input placeholder='Please Input Shopify Product Id Here' type='number' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ApplicationShopifyModal;
