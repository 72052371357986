import { init, Plugin, RematchDispatch, RematchRootState } from '@rematch/core';
import immerPlugin from '@rematch/immer';
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading';
import createPersistPlugin, { getPersistor } from '@rematch/persist';
import selectPlugin from '@rematch/select';
import { omit, pick } from 'lodash';
import { createTransform } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import sessionStorage from 'redux-persist/es/storage/session';

import { models, RootModel } from './models';
import { dropFitInitialState, DropFitStateType } from './models/dropFit';

type PersistStateType = DropFitStateType;

const notAllowedList = ['searchContext'];
const allowedList = ['search'];

const SetTransform = createTransform(
  (inboundState: PersistStateType) => {
    const filtered = pick(inboundState, allowedList);
    const filteredSearch = omit(filtered.search, notAllowedList);
    return {
      search: { ...filteredSearch },
    };
  },
  (outboundState: PersistStateType) => {
    return {
      ...dropFitInitialState,
      search: {
        ...dropFitInitialState.search,
        ...outboundState.search,
      },
    };
  },
  { whitelist: ['houyidai'] },
);

export type FullModel = ExtraModelsFromLoading<RootModel, { type: 'full' }>;
const persistPlugin: Plugin<RootModel, FullModel, Partial<FullModel>> = createPersistPlugin({
  key: 'root',
  storage: sessionStorage,
  stateReconciler: autoMergeLevel1,
  blacklist: ['auth', 'loading'],
  transforms: [SetTransform],
});

export const store = init<RootModel, FullModel>({
  redux: {
    rootReducers: {
      ResetApp: () => ({
        houyidai: { ...dropFitInitialState },
      }),
    },
  },
  models,
  plugins: [persistPlugin, loadingPlugin({ type: 'full' }), immerPlugin(), selectPlugin()],
});

export const persistor = getPersistor();
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
