import 'antd/dist/reset.css';
import './App.css';

import api from 'api';
import { Login } from 'pages';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import DropFitIndex from './routes';

const App = () => (
  <BrowserRouter>
    {!api.isAuthentication ? (
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<Navigate to='/login' replace />} />
      </Routes>
    ) : (
      <Routes>
        <Route path='/*' element={<DropFitIndex />} />
      </Routes>
    )}
  </BrowserRouter>
);

export default App;
