import { Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { RuleAction, RuleConditionData, RuleData, RuleFrequency } from 'api/interface';
import { DynamicTable, PageHeader } from 'components';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import api from '../../../api';
import { SearchItemType } from '../../../components/FilterBar';
import { DetailsType, ExtBtnType } from '../../../global';
import RuleDetailsModal from './RuleDetailsModal';

const Rules = () => {
  const { search } = useSelector((state: RootState) => state.dropFit);
  const { loading } = useSelector((state: RootState) => state.loading.effects.dropFit.searchRules);
  const dispatch = useDispatch<Dispatch>();
  const [openModal, setOpenModal] = useState(false);
  const [detailsType, setDetailsType] = useState<DetailsType>(DetailsType.CREATE);
  const [ruleId, setRuleId] = useState<string>();
  const [ruleName, setRuleName] = useState<string>();
  const [rulePlatform, setRulePlatform] = useState<string>();
  const [ruleConditions, setRuleConditions] = useState<RuleConditionData[]>([]);
  const [ruleAction, setRuleAction] = useState<RuleAction>({});
  const [ruleFrequencies, setRuleFrequencies] = useState<RuleFrequency[]>([]);

  const handleCreate = () => {
    setDetailsType(DetailsType.CREATE);
    setOpenModal(true);
  };

  const handleEdit = (data: RuleData) => {
    setRuleId(data.ruleId);
    setRuleName(data.ruleName);
    setRulePlatform(data.platform);
    setRuleConditions(data.ruleConditions || []);
    setRuleAction(data.ruleAction || {});
    setRuleFrequencies(data.ruleFrequencies || []);
    setDetailsType(DetailsType.EDIT);
    setOpenModal(true);
  };

  const handleDelete = (ruleId?: string) => {
    if (ruleId == undefined || ruleId === '') {
      return;
    }
    Modal.confirm({
      title: 'Delete Rule',
      content:
        'Are you confirm to delete? All shops that use this rule will have no rules attached and you should reattach the rule to the shops!',
      icon: null,
      okText: 'OK',
      cancelText: 'Cancel',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        await api.RuleApi.deleteRule(ruleId);
        dispatch.dropFit.reloadTable(true);
      },
    });
  };

  const columns: ColumnsType<RuleData> = [
    { title: 'Platform', align: 'center', dataIndex: 'platform' },
    { title: 'Rule Name', align: 'center', dataIndex: 'ruleName' },
    {
      title: 'Operations',
      align: 'center',
      render: (data: RuleData) => (
        <>
          <Button type='link' onClick={() => handleEdit(data)}>
            Edit Rule
          </Button>
          <Button type='link' onClick={() => handleDelete(data.ruleId)}>
            Delete Rule
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Rule Management' extBtnType={ExtBtnType.CREATE} extBtnAction={handleCreate} />

      <DynamicTable
        columns={columns}
        fetch={dispatch.dropFit.searchRules}
        initialContext={search?.rulesFetchParams}
        dataSource={search?.searchContext?.rows}
        searchItems={[
          {
            label: 'Platform',
            type: SearchItemType.SELECT,
            options: [
              { label: 'FACEBOOK', value: 'FACEBOOK' },
              { label: 'PINTEREST', value: 'PINTEREST' },
            ],
            key1: 'platform',
          },
          {
            label: 'Rule Name',
            type: SearchItemType.STRING,
            key1: 'ruleName',
          },
        ]}
        totalCount={search?.searchContext?.totalCount}
        pagination={false}
        rowKey='ruleId'
        loading={loading || false}
      />

      <RuleDetailsModal
        open={openModal}
        setOpen={setOpenModal}
        detailsType={detailsType}
        ruleId={ruleId}
        ruleName={ruleName}
        rulePlatform={rulePlatform}
        setRulePlatform={setRulePlatform}
        setRuleName={setRuleName}
        ruleConditions={ruleConditions}
        setRuleConditions={setRuleConditions}
        ruleAction={ruleAction}
        setRuleAction={setRuleAction}
        ruleFrequencies={ruleFrequencies}
        setRuleFrequencies={setRuleFrequencies}
      />
    </>
  );
};

export default Rules;
