import { ConfigProvider, Input, message } from 'antd';
import api from 'api';
import { PageFooter, PageHeader } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

const Line = () => {
  const [lineToken, setLineToken] = useState('');
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    dispatch.dropFit.loadingPage(true);
    api.UserApi.getUserInfo()
      .then(res => {
        if (res.data.lineToken != undefined) {
          setLineToken(res.data.lineToken);
        }
      })
      .finally(() => {
        dispatch.dropFit.loadingPage(false);
      });
  }, []);
  const handleSubmit = async () => {
    dispatch.dropFit.loadingPage(true);

    try {
      await api.UserApi.updateLineToken(lineToken);
      message.success({
        content: 'Success!',
      });
    } catch (err) {
      console.log(err);
    }

    dispatch.dropFit.loadingPage(false);
  };

  return (
    <>
      <PageHeader title='LINE Token Config' />

      <div className='page-content'>
        <ConfigProvider theme={{ token: { colorBorder: 'transparent', borderRadius: 0 } }}>
          <div className='grid-content grid-6'>
            <div className='grid-item'>
              <div className='label'>LINE Token</div>
              <Input placeholder='Input LINE Token Here' value={lineToken} onChange={e => setLineToken(e.currentTarget.value)} />
            </div>
          </div>
        </ConfigProvider>
      </div>

      <PageFooter onOk={handleSubmit} />
    </>
  );
};

export default Line;
