import { createModel } from '@rematch/core';
import api from 'api';
import type { CampaignFetchParams, ConnectedShopsFetchParams, FetchArguments, RulesFetchParams, UsersFetchParams } from 'api/interface';
import type { DynamicTableContext } from 'components/DynamicTable';

import { RootModel } from '.';

interface DropFitSearchType {
  searchContext?: DynamicTableContext;
  usersFetchParams?: UsersFetchParams;
  rulesFetchParams?: RulesFetchParams;
  connectedShopsFetchParams?: ConnectedShopsFetchParams;
  campaignsFetchParams?: CampaignFetchParams;
}

export interface DropFitStateType {
  sidebarCollapsed?: boolean;
  reloadTable?: false;
  loadingPage?: false;
  search?: DropFitSearchType;
}

export const initialFetch: FetchArguments = {
  pageSize: 20,
  currentPage: 1,
  search: {},
  fixed: {},
};

export const dropFitInitialState: DropFitStateType = {
  sidebarCollapsed: false,
  reloadTable: false,
  loadingPage: false,
  search: {
    searchContext: {
      rows: [],
      totalCount: 0,
    },
    usersFetchParams: { ...initialFetch },
    rulesFetchParams: { ...initialFetch },
    connectedShopsFetchParams: { ...initialFetch },
    campaignsFetchParams: { ...initialFetch, fixed: { shopId: '' } },
  },
};

export const dropFit = createModel<RootModel>()({
  state: { ...dropFitInitialState },
  reducers: {
    reverseSidebarCollapsed: (state: DropFitStateType) => ({
      ...state,
      sidebarCollapsed: !state.sidebarCollapsed,
    }),
    reloadTable: (state: DropFitStateType, payload) => ({
      ...state,
      reloadTable: payload,
    }),
    loadingPage: (state: DropFitStateType, payload) => ({
      ...state,
      loadingPage: payload,
    }),
    searchContext: (state: DropFitStateType, payload) => ({
      ...state,
      search: { ...state.search, searchContext: { ...payload } },
    }),
    usersFetchParams: (state: DropFitStateType, payload) => ({
      ...state,
      search: { ...state.search, usersFetchParams: { ...payload } },
    }),
    campaignsFetchParams: (state: DropFitStateType, payload) => ({
      ...state,
      search: { ...state.search, campaignsFetchParams: { ...payload } },
    }),
    connectedShopsFetchParams: (state: DropFitStateType, payload) => ({
      ...state,
      search: { ...state.search, connectedShopsFetchParams: { ...payload } },
    }),
  },
  effects: dispatch => ({
    initSearch() {
      dispatch.dropFit.searchContext({
        rows: [],
        totalCount: 0,
      });
    },
    async searchConnectedShops(fetchParams: ConnectedShopsFetchParams) {
      try {
        const res = await api.ShopApi.getConnectedShopList(fetchParams);
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });
      }
    },
    async searchGuestShops() {
      try {
        const res = await api.ShopApi.getGuestShopList();
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });
      }
    },
    async searchShops() {
      try {
        const res = await api.ShopApi.getShopList();
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });
      }
    },
    async searchRules(fetchParams: RulesFetchParams) {
      try {
        const res = await api.RuleApi.getRuleList(fetchParams);
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });
      }
    },
    async searchUsers(fetchParams: UsersFetchParams) {
      try {
        const res = await api.UserApi.getUserList(fetchParams);
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length === 0 ? 0 : res.data[0].totalCount,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });
      }

      dispatch.dropFit.usersFetchParams(fetchParams);
    },
    async searchSubUsers(fetchParams: UsersFetchParams) {
      try {
        const res = await api.UserApi.getSubUserList(fetchParams);
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length === 0 ? 0 : res.data[0].totalCount,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });
      }

      dispatch.dropFit.usersFetchParams(fetchParams);
    },
    async searchChildGuests(fetchParams: UsersFetchParams) {
      try {
        const res = await api.UserApi.getChildGuestList(fetchParams);
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length === 0 ? 0 : res.data[0].totalCount,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });
      }

      dispatch.dropFit.usersFetchParams(fetchParams);
    },
    async searchGuestApplications(fetchParams: CampaignFetchParams) {
      try {
        const res = await api.ApplicationApi.getApplicationList(fetchParams.fixed?.shopId, fetchParams);
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length === 0 ? 0 : res.data[0].totalCount,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });

        dispatch.dropFit.campaignsFetchParams(fetchParams);
      }
    },
    async searchGuestCampaigns(fetchParams: CampaignFetchParams) {
      try {
        const res = await api.CampaignApi.getGuestCampaignList(fetchParams.fixed?.shopId, fetchParams);
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length === 0 ? 0 : res.data[0].totalCount,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });

        dispatch.dropFit.campaignsFetchParams(fetchParams);
      }
    },
    async searchCampaigns(fetchParams: CampaignFetchParams) {
      try {
        const res = await api.CampaignApi.getCampaignList(fetchParams.fixed?.shopId, fetchParams);
        dispatch.dropFit.searchContext({
          rows: res.data,
          totalCount: res.data.length === 0 ? 0 : res.data[0].totalCount,
        });
      } catch {
        dispatch.dropFit.searchContext({
          rows: [],
          totalCount: 0,
        });

        dispatch.dropFit.campaignsFetchParams(fetchParams);
      }
    },
  }),
});
