import { Route, Routes } from 'react-router-dom';

import { Line, SettingsChangePassword, SubGuests, SubUsers, Users } from '../pages';

const Settings = () => (
  <Routes>
    <Route path='/members' element={<Users />} />
    <Route path='/sub-accounts' element={<SubUsers />} />
    <Route path='/childGuests' element={<SubGuests />} />
    <Route path='/change-password' element={<SettingsChangePassword />} />
    <Route path='/line' element={<Line />} />
  </Routes>
);

export default Settings;
